import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
} from "@mui/material";

import defimg from "../../Image/default-banner.jpg";
import { useState } from "react";
import { UserContext } from "../../Componants/Context/UserContext";
import ImageUpload from "../../Componants/ImageUpload/ImageUpload";
import ImagedataAppend from "../../Componants/ImageUpload/ImagedataAppend";
import { DeleteShowImg, SaveShow, UpdateShowImg } from "../../API/ShowModel";
import {
  alphanumeric,
  customvalidation,
  formatDatetime_dmy,
  getHourTime,
  IMG_URL,
  onlydecimal,
  onlynumeric,
  tostmsg,
} from "../../MIS/Global";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import { ShowStore } from "../../Componants/Context/ShowReducer";
import FileUpload from "../../Componants/FileUplode/FileUplode";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import AlertMessageComp from "../../Componants/AlertMessageCom/AlertMessageComp";
import { TicketReducer } from "../../Reducers/ShowReducer";
import { useReducer } from "react";
import { House, Label } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker, DatePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { useEffect } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs(handleClose) {
  function formatISOToCustom(dateString) {
    // Parse the ISO string to a Date object
    const date = new Date(dateString);
  
    // Extract the components of the date
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  
    // Combine the components into the desired format
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  
    return formattedDate;
  }
  const { showedit, setShowedit } = React.useContext(UserContext);
  // alert(JSON.stringify(showedit));
  // alert(showedit[0]?.showdetail[0]?.showname);
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [vendrid, setVendrid] = useState(showedit[0]?.vendrid || 0);
  const [vendrname, setVendrname] = useState(
    showedit[0]?.showdetail[0]?.vendrname || ""
  );
  const [showid, setShowid] = useState(showedit[0]?.showid || 0);
  const [file, setFile] = useState(null);
  const imgPath = `${IMG_URL}${showedit[0]?.drawimg}`;
  const [imageSrc, setImageSrc] = useState(
    showedit[0]?.drawimg != "-" ? imgPath : defimg
  );
  const [eventname, setEventname] = useState(
    showedit[0]?.showdetail[0]?.eventname || "Single Event"
  );
  const [cardleft, setCardleft] = useState(
    showedit[0]?.showdetail[0]?.cardleft || ""
  );
  const [eventid, setEventid] = useState(0);
  const [venuname, setVenuname] = useState(
    showedit[0]?.showdetail[0]?.venuname || ""
  );
  const [jackpotvalue, setJackpotvalue] = useState(
    showedit[0]?.showdetail[0]?.jackpotvalue || ""
  );

  const [venueid, setVenueid] = useState(showedit[0]?.venueid || 0);
  const [show, setShow] = useState(showedit[0]?.showdetail[0]?.showname || "");
  // const [descrp, setDescrp] = useState(showedit[0]?.showdetail[0]?.descrp || '')
  const [descrp, setDescrp] = useState(
    showedit[0]?.descr.replace(/#newline#/g, "\n") || ""
  );
  const [ticketinfo, setTicketinfo] = useState(
    showedit[0]?.showdetail[0]?.ticketinfo || ""
  );
  const [ticketblurb, setTicketblurb] = useState(
    showedit[0]?.showdetail[0]?.ticketblurb || ""
  );
  const [isstatus, setIsstatus] = useState(showedit[0]?.isstatus || "");
  const [uid, setUid] = useState(showedit[0]?.showdetail[0]?.uid || 0);
  const [showtime, setShowtime] = useState(
    formatISOToCustom(showedit[0]?.show_time) || new Date()
  );
  const [salestart, setSalestart] = useState(
    formatISOToCustom(showedit[0]?.sale_start) || new Date()
  );
  const [salesend, setSalesend] = useState(formatISOToCustom(showedit[0]?.sale_end) || new Date());

  const [convfee1, setConvfee1] = useState(
    showedit[0]?.feestaxdet[0]?.convfee1 || ""
  );
  const [convfeesplit, setConvfeesplit] = useState(
    showedit[0]?.feestaxdet[0]?.convfeesplit || ""
  );
  const [convfee2, setConvfee2] = useState(
    showedit[0]?.feestaxdet[0]?.convfee2 || ""
  );
  const [additionalfee, setAdditionalfee] = useState(
    showedit[0]?.feestaxdet[0]?.additionalfee || "10"
  );
  const [tax, setTax] = useState(showedit[0]?.feestaxdet[0]?.tax || "");
  const [donation, setDonation] = React.useState(
    showedit[0]?.showdetail[0]?.donation || "no"
  );
  const [tickettax, setTickettax] = React.useState(
    showedit[0]?.feestaxdet[0]?.tickettax || "yes"
  );
  const [convfeetax, setConvfeetax] = React.useState("yes");
  const [blob, setBlob] = React.useState(showedit[0]?.drawimg || "");

  const [rtickets, setRtickets] = React.useState(
    showedit[0]?.showdetail[0]?.rtickets || ""
  );

  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);

  const [error, setError] = useState("");

  const [imgdata, setImgdata] = useState([]);
  const [newtier, setNewtier] = useState("");
  const [qty, setQty] = useState("");
  const [price, setPrice] = useState("");
  const [ticket, ticket_dispatch] = useReducer(
    TicketReducer,
    showedit[0]?.ticketdet || []
  );

 

  let data = {
    showid: showid,
    showdetail: [
      {
        showname: show,
        vendrname: vendrname,
        eventname: eventname,
        donation: donation,
        descrp: "",
        ticketinfo: ticketinfo,
        ticketblurb: ticketblurb,
        venuname: venuname,
        rtickets: rtickets,
        jackpotvalue: jackpotvalue,
        cardleft: cardleft,
      },
    ],
    showdatedet: [
      {
        showtime: showtime,
        salestart: salestart,
        salesend: salesend,
      },
    ],
    ticketdet: ticket,
    feestaxdet: [
      {
        convfee1: convfee1,
        convfeesplit: convfeesplit,
        convfee2: convfee2,
        additionalfee: additionalfee,
        tax: tax,
        tickettax: tickettax,
        convfeetax: convfeetax,
      },
    ],
    isstatus: isstatus,
    uid: uid,
    drawimg: showedit[0]?.drawimg,
    show_time: showtime,
    sale_start: salestart,
    sale_end: salesend,
    vendrid: vendrid,
    venueid: venueid,
    descr: descrp,
  };
 
  const handleOnChange = (e, val) => {
    let updateDate;
    if (e === "showtime") {
      let month = new Intl.DateTimeFormat("en-US", {
        month: "2-digit",
      }).format(val);
      let day = new Intl.DateTimeFormat("en-US", {
        day: "2-digit",
      }).format(val);
      let year = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
      }).format(val);
      let hour = new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        hour12: false,
      }).format(val);
      let minute = new Intl.DateTimeFormat("en-US", {
        minute: "numeric",
        hour12: false,
      }).format(val);
      let xDate = `${year}-${month}-${day} ${hour}:${minute}:00`;
      setShowtime(xDate);
    } else if (e === "starttime") {
      let month = new Intl.DateTimeFormat("en-US", {
        month: "2-digit",
      }).format(val);
      let day = new Intl.DateTimeFormat("en-US", {
        day: "2-digit",
      }).format(val);
      let year = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
      }).format(val);
      let hour = new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        hour12: false,
      }).format(val);
      let minute = new Intl.DateTimeFormat("en-US", {
        minute: "numeric",
        hour12: false,
      }).format(val);
      let xDate = `${year}-${month}-${day} ${hour}:${minute}:00`;
      setSalestart(xDate);
    } else if (e === "endtime") {
      let month = new Intl.DateTimeFormat("en-US", {
        month: "2-digit",
      }).format(val);
      let day = new Intl.DateTimeFormat("en-US", {
        day: "2-digit",
      }).format(val);
      let year = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
      }).format(val);
      let hour = new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        hour12: false,
      }).format(val);
      let minute = new Intl.DateTimeFormat("en-US", {
        minute: "numeric",
        hour12: false,
      }).format(val);

      let xDate = `${year}-${month}-${day} ${hour}:${minute}:00`;

      setSalesend(xDate);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleAddTicket = () => {
    let validRet = {};
    if (ticket.length == 3) {
      validRet = { msg: "Maximum three(3) tickets allow", code: 100 };
    }
    if (newtier.length <= 0) {
      validRet = { msg: "Enter Tier Name", code: 100 };
    }
    // if (qty <= 0) {
    //   validRet = { msg: "Enter valid quantity", code: 100 };
    // }
    if (price <= 0) {
      validRet = { msg: "Enter valid price", code: 100 };
    }
    let rettostmsg = tostmsg(validRet);
    if (validRet.code == 100) {
      setAlertdata(rettostmsg);
      setAlertopen(rettostmsg.open);
      return;
    }

    ticket_dispatch({
      type: "added",
      text: newtier,
      qty: qty,
      price: price,
    });
    setNewtier("");
    setQty("");
    setPrice("");
  };
  function handleChangeTask(tid) {
    ticket_dispatch({
      type: "changed",
      id: tid,
      text: newtier,
      qty: qty,
      price: price,
    });
    setNewtier("");
    setQty("");
    setPrice("");
  }
  const handleDeleteTicket = (tid) => {
    ticket_dispatch({
      type: "deleted",
      id: tid,
    });
  };

  const showdate = () => {
    // alert(showtime);
    // alert(salestart);
    let month = new Date(showedit[0]?.show_time).getMonth() + 1;
    if (month.toString().length <= 1) {
      month = `0${month}`;
    }
    let day = new Date(showedit[0]?.show_time).getDate();
    if (day.toString().length <= 1) {
      day = `0${day}`;
    }
    let hours = new Date(showedit[0]?.show_time).getHours();
    if (hours.toString().length <= 1) {
      hours = `0${hours}`;
    }
    let minutes = new Date(showedit[0]?.show_time).getMinutes();
    if (minutes.toString().length <= 1) {
      minutes = `0${minutes}`;
    }
    let year = new Date(showedit[0]?.show_time).getFullYear();
    // year = Number(year) - Number(e.target.value);
    setShowtime(`${year}-${month}-${day} ${hours}:${minutes}`);
  };

  const calcBirthdate = () => {
    let month = new Date(showedit[0]?.sale_start).getMonth() + 1;
    if (month.toString().length <= 1) {
      month = `0${month}`;
    }
    let day = new Date(showedit[0]?.sale_start).getDate();
    if (day.toString().length <= 1) {
      day = `0${day}`;
    }
    let hours = new Date(showedit[0]?.sale_start).getHours();
    if (hours.toString().length <= 1) {
      hours = `0${hours}`;
    }
    let minutes = new Date(showedit[0]?.sale_start).getMinutes();
    if (minutes.toString().length <= 1) {
      minutes = `0${minutes}`;
    }
    let year = new Date(showedit[0]?.sale_start).getFullYear();
    // year = Number(year) - Number(e.target.value);
    setSalestart(`${year}-${month}-${day} ${hours}:${minutes}`);
  };

  const saleend = () => {
    let month = new Date(showedit[0]?.sale_end).getMonth() + 1;
    if (month.toString().length <= 1) {
      month = `0${month}`;
    }
    let day = new Date(showedit[0]?.sale_end).getDate();
    if (day.toString().length <= 1) {
      day = `0${day}`;
    }
    let hours = new Date(showedit[0]?.sale_end).getHours();
    if (hours.toString().length <= 1) {
      hours = `0${hours}`;
    }
    let minutes = new Date(showedit[0]?.sale_end).getMinutes();
    if (minutes.toString().length <= 1) {
      minutes = `0${minutes}`;
    }
    let year = new Date(showedit[0]?.sale_end).getFullYear();
    // year = Number(year) - Number(e.target.value);
    // alert(`${year}-${month}-${day} ${hours}:${minutes}`)
    setSalesend(`${year}-${month}-${day} ${hours}:${minutes}`);
  };

  const datevalidate = () => {
    if (showtime < salestart) {
      return "0";
    } else if (salestart > salesend) {
      return "1";
    }
    return "200";
  };

  const handleSaveImgData = () => {
    const formData = new FormData();
    formData.append("showid", showid);
    formData.append("oldpath", showedit[0]?.drawimg);
    formData.append("file", file);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(event.target.files[0]);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
      handleSaveImgData();
    }
  };

  return (
    <Box sx={{ bgcolor: "background.paper" }}>
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Draw Details" {...a11yProps(0)} />
          <Tab label="Date/Times" {...a11yProps(1)} />
          <Tab label="Tickets" {...a11yProps(2)} />
          <Tab label="Fees & Tax" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Grid container spacing={2} sx={{ height: "260px" }}>
            <Grid item md={12} xs={12}>
              <TextField
                size="small"
                id="outlined-case no-input"
                fullWidth
                value={show}
                label="Draw Name"
                inputProps={{ maxLength: 40 }}
                onChange={async (e) => {
                  let retdt = await alphanumeric(e);
                  setShow(retdt);
                }}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ display: "none" }}>
              <TextField
                size="small"
                id="outlined-case no-input"
                fullWidth
                label="Vendor"
                value={vendrname}
                inputProps={{ maxLength: 20, readOnly: true }}
                onChange={async (e) => {
                  let retdt = await alphanumeric(e);
                  setVendrname(retdt);
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              {/* <TextField
                size="small"
                id="outlined-case no-input"
                fullWidth
                label="Event"
                value={eventname}
                inputProps={{ maxLength: 20, readOnly: true }}
                onChange={async (e) => {
                  let retdt = await alphanumeric(e)
                  setEventname(retdt)
                }}
              /> */}
              <TextField
                size="small"
                id="outlined-case no-input"
                fullWidth
                label="Cards Left"
                value={cardleft}
                inputProps={{ maxLength: 20, readOnly: false }}
                onChange={async (e) => {
                  let retdt = await alphanumeric(e);
                  setCardleft(retdt);
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                size="small"
                id="outlined-case no-input"
                fullWidth
                label="Remaning Cards" //Remaning Tickets
                value={rtickets}
                inputProps={{ maxLength: 20 }}
                onChange={async (e) => {
                  let retdt = await onlynumeric(e);
                  setRtickets(retdt);
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                size="small"
                id="outlined-case no-input"
                fullWidth
                label="Jackpot Value"
                value={jackpotvalue}
                inputProps={{ maxLength: 20 }}
                onChange={async (e) => {
                  let re = /^[0-9.$,\b\s]+$/;
                  let retdt = await customvalidation(e, re);
                  setJackpotvalue(retdt);
                }}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ display: "none" }}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Donation
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={donation}
                onChange={(e) => {
                  // alert(e.target.value)
                  setDonation(e.target.value);
                }}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextareaAutosize
                minRows={3}
                placeholder="Description"
                style={{
                  width: "100%",
                  border: "1px solid black",
                  borderRadius: "2px",
                }}
                value={descrp}
                onChange={async (e) => {
                  // let retdt = await alphanumeric(e)
                  setDescrp(e.target.value);
                }}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ display: "none" }}>
              <TextareaAutosize
                minRows={3}
                placeholder="Ticket Info"
                style={{
                  width: "100%",
                  border: "1px solid black",
                  borderRadius: "2px",
                }}
                value={ticketinfo}
                onChange={async (e) => {
                  // let retdt = await alphanumeric(e)
                  setTicketinfo(e.target.value);
                }}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ display: "none" }}>
              <TextareaAutosize
                minRows={3}
                placeholder="Ticket Blurb"
                style={{
                  width: "100%",
                  border: "1px solid black",
                  borderRadius: "2px",
                }}
                value={ticketblurb}
                onChange={async (e) => {
                  // let retdt = await alphanumeric(e)
                  setTicketblurb(e.target.value);
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                size="small"
                id="outlined-case no-input"
                fullWidth
                label="Venue"
                inputProps={{ maxLength: 20, readOnly: true }}
                value={venuname}
                onChange={async (e) => {
                  let retdt = await alphanumeric(e);
                  setVenuname(retdt);
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Button
                variant="contained"
                component="label"
                sx={{ bottom: "10px" }}
              >
                Upload Banner
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={handleFileChange}
                />
              </Button>
              <img width="100%" src={imageSrc} />
              {/* <img width="100%" src={defimg} /> */}
              {/* <input type="file" onChange={handleFileChange} /> */}
              {/* <FileUpload
                imgdata={imgdata}
                setImgdata={setImgdata}
                blob={blob}
                setBlob={setBlob}
                imgcount={0}
              /> */}
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              {/* <TextField
                id="date"
                fullWidth
                label="Draw Time"
                type="datetime-local"
                value={showtime}
                // defaultValue="2017-05-24T10:30"
                sx={{ width: 'auto' }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: { step: 300 }, // Optional: Set the time step to 5 minutes
                 }}
                onChange={(e) => {
                  setShowtime(e.target.value)
                }}              
              /> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label={"Draw Time"}
                  name="showtime"
                  // className={classes.smallDateTimePicker}
                  // defaultValue={dayjs()}
                  // minDate={dayjs()}
                  ampm={true} // 12-hour format (false for 24-hour format)
                  value={showtime}
                  inputFormat="DD-MM-YYYY hh:mm A"
                  onChange={(e) => {
                    const selectedDayjs = dayjs(e);
                    setShowtime(e);
                    handleOnChange("showtime", e);
                    // alert(e)
                    // setShowtime(e);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={12} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label={"Sale Start"}
                  name="starttime"
                  // className={classes.smallDateTimePicker}
                  // defaultValue={dayjs()}
                  // minDate={dayjs()}
                  inputFormat="DD-MM-YYYY hh:mm A"
                  ampm={true} // 12-hour format (false for 24-hour format)
                  value={salestart}
                  onChange={(e) => {
                    setSalestart(e);
                    handleOnChange("starttime", e);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
             
            </Grid>
            <Grid item md={6} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label={"Sale End"}
                  name="endtime"
                  // className={classes.smallDateTimePicker}
                  // defaultValue={dayjs()}
                  // minDate={dayjs()}
                  inputFormat="DD-MM-YYYY hh:mm A"
                  ampm={true} // 12-hour format (false for 24-hour format)
                  value={salesend}
                  onChange={(e) => {
                    setSalesend(e);
                    handleOnChange("endtime", e);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  PopperProps={{
                    modifiers: [
                      {
                        name: "flip",
                        enabled: true,
                        options: {
                          fallbackPlacements: ["top"],
                        },
                      },
                      {
                        name: "offset",
                        options: {
                          offset: [0, 8],
                        },
                      },
                      {
                        name: "preventOverflow",
                        options: {
                          altAxis: true,
                          altBoundary: true,
                          tether: true,
                          rootBoundary: "viewport",
                        },
                      },
                    ],
                  }}
                />
              </LocalizationProvider>
           
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Grid item md={4} xs={12}>
              <TextField
                size="small"
                id="outlined-case no-input"
                fullWidth
                label="New Tier"
                value={newtier}
                inputProps={{ maxLength: 20 }}
                onChange={async (e) => {
                  let retdt = await alphanumeric(e);
                  setNewtier(retdt);
                }}
              />
            </Grid>
            <Grid item md={4} xs={12} display="none">
              <TextField
                size="small"
                id="outlined-case no-input"
                fullWidth
                label="Qty"
                value={qty}
                inputProps={{ maxLength: 20 }}
                onChange={async (e) => {
                  let retdt = await onlynumeric(e);
                  setQty(retdt);
                }}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                size="small"
                id="outlined-case no-input"
                fullWidth
                label="Price"
                value={price}
                inputProps={{ maxLength: 20 }}
                onChange={async (e) => {
                  let retdt = await onlynumeric(e);
                  setPrice(retdt);
                }}
              />
            </Grid>

            <Grid item md={1} xs={12}>
              <AddCircleIcon
                style={{ fontSize: "30px", cursor: "pointer" }}
                onClick={handleAddTicket}
              />
            </Grid>

            <Grid
              container
              item
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              {ticket.map((task1) => {
                return (
                  <>
                    <Grid item md={4} xs={12}>
                      {task1.text}
                    </Grid>
                    <Grid item md={4} xs={12} display="none">
                      {task1.qty}
                    </Grid>
                    <Grid item md={3} xs={12}>
                      ${Number(task1.price).toFixed(2)}
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <DeleteIcon
                        style={{ fontSize: "30px", cursor: "pointer" }}
                        onClick={(e) => handleDeleteTicket(task1.id)}
                      />
                    </Grid>
                    {/* <Grid item md={4} xs={12}>
                <TextField
                  size="small"
                  id="outlined-case no-input"
                  fullWidth
                  label="New Tier"
                  value={task1.text}
                  inputProps={{ maxLength: 20 }}
                  onChange={async (e) => {
                    let retdt = await alphanumeric(e);
                    setNewtier(retdt);
                    handleChangeTask(task1.id);
                  }}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  size="small"
                  id="outlined-case no-input"
                  fullWidth
                  label="Qty"
                  value={task1.qty}
                  inputProps={{ maxLength: 20 }}
                  onChange={async (e) => {
                    let retdt = await onlynumeric(e);
                    setQty(retdt);
                  }}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  size="small"
                  id="outlined-case no-input"
                  fullWidth
                  label="Price"
                  value={task1.price}
                  inputProps={{ maxLength: 20 }}
                  onChange={async (e) => {
                    let retdt = await onlynumeric(e);
                    setPrice(retdt);
                  }}
                />
              </Grid>
              <Grid item md={1} xs={12}>
                 <DeleteIcon
                  style={{ fontSize: "30px",cursor:"pointer" }}
                  onClick={(e) => handleDeleteTicket(task1.id)}
                />
              </Grid> */}
                  </>
                );
              })}
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <Grid container spacing={2} sx={{}}>
            <Grid item md={12} xs={12}>
              <TextField
                size="small"
                id="outlined-case no-input"
                fullWidth
                label="Convenience Fee"
                value={convfee1}
                inputProps={{ maxLength: 20 }}
                onChange={async (e) => {
                  let retdt = await onlydecimal(e);
                  setConvfee1(retdt);
                  setAdditionalfee(0);
                }}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ display: "none" }}>
              <TextField
                size="small"
                id="outlined-case no-input"
                fullWidth
                value={convfeesplit}
                label="Conv. fee Split"
                inputProps={{ maxLength: 20 }}
                onChange={async (e) => {
                  let retdt = await onlydecimal(e);
                  setConvfeesplit(retdt);
                }}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ display: "none" }}>
              <TextField
                size="small"
                id="outlined-case no-input"
                fullWidth
                value={convfee2}
                label="Conv. fee 2"
                inputProps={{ maxLength: 20 }}
                onChange={async (e) => {
                  let retdt = await onlynumeric(e);
                  setConvfee2(retdt);
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                size="small"
                id="outlined-case no-input"
                fullWidth
                value={additionalfee}
                label="Convenience fee in(%)"
                inputProps={{ maxLength: 6 }}
                onChange={async (e) => {
                  let retdt = await onlydecimal(e);
                  setAdditionalfee(retdt);
                  setConvfee1(0);
                }}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ display: "none" }}>
              <TextField
                size="small"
                id="outlined-case no-input"
                fullWidth
                value={tax}
                label="Tax(%)"
                inputProps={{ maxLength: 2 }}
                onChange={async (e) => {
                  let retdt = await onlynumeric(e);
                  setTax(retdt);
                }}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ display: "" }}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Convenience Fee
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={tickettax}
                onChange={async (e) => {
                  setTickettax(e.target.value);
                }}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item md={12} xs={12} sx={{ display: "none" }}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Convenience Fee
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={convfeetax}
                onChange={(e) => {
                  setConvfeetax(e.target.value);
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio checked />}
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="No"
                  disabled
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </TabPanel>
      </SwipeableViews>
      <Grid item md={12} xs={12} sm={12}>
        <Button
          variant="contained"
          type="button"
          style={{ float: "right" }}
          onClick={() => {
            (async () => {
              try {
                if (datevalidate() == "0") {
                  let rettostmsg = tostmsg({
                    code: 100,
                    msg: "Draw date should be greater than start & end date",
                  });
                  setAlertdata(rettostmsg);
                  setAlertopen(rettostmsg.open);
                  return;
                } else if (datevalidate() == "1") {
                  let rettostmsg = tostmsg({
                    code: 100,
                    msg: "Start date should be greater than end date",
                  });
                  setAlertdata(rettostmsg);
                  setAlertopen(rettostmsg.open);
                  return;
                }
                // console.log(data);
                // alert();
                const show = await SaveShow(data);

                let rettostmsg = tostmsg(show);
                // console.log(show);
                setAlertdata(rettostmsg);
                setAlertopen(rettostmsg.open);

                if (show.code == 100) {
                }

                if (show.code == 200) {
                  setTimeout(async () => {
                    if (file === null) {
                      handleClose();
                    } else {
                      const formData = new FormData();
                      formData.append("showid", showid);
                      formData.append("oldpath", showedit[0]?.drawimg);
                      formData.append("file", file);
                      let imgData = await UpdateShowImg(formData);
                      // alert(JSON.stringify(imgData));
                      handleClose();
                    }
                  });
                }
                window.location.reload()
              } catch (e) {}
            })();
          }}
        >
          <DoneIcon /> Submit
        </Button>
      </Grid>
    </Box>
  );
}
