import React from "react";
import Footer from "../../Componants/Navbar/Footer";
import NavbarComp from "../../Componants/Navbar/NavbarComp";
 import SidebarComp from "../../Componants/Sidebar/SidebarComp";
 import Venues from "../../Componants/Venues/Venues";
 
const VenuesScreen = () => {
  return (
    <div>
      <SidebarComp />
      <NavbarComp />
     <Venues/>
     <Footer/>
    </div>
  );
};

export default VenuesScreen;
