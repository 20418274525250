import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import React from "react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DoneIcon from "@mui/icons-material/Done";
import { Link } from "react-router-dom";
import ReportTbl from "../../Table/ReportTbl";
import OrderReportTbl from "../../Table/OrderReportTbl";
// import { CSVLink, CSVDownload } from "react-csv";

// const csvData = [
//   ["firstname", "lastname", "email"],
//   ["Ahmed", "Tomi", "ah@smthing.co.com"],
//   ["Raed", "Labes", "rl@smthing.co.com"],
//   ["Yezzi", "Min l3b", "ymin@cocococo.com"]
// ];
// <CSVLink data={csvData}>Download me</CSVLink>;

// import * as fs from 'fs'cls

const ReportList = () => {
  const Owner = [
    { label: "Wade", id: "Wade" },
    { label: "Dave", id: "Dave" },
  ];
   
 

  return (
    <div className="main-content">
      <main>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item md={12} xs={12} sm={12}>
            <h3 className="supcolor3">Order Report</h3>
            <hr />
          </Grid>

          <Grid item md={12} xs={12} sm={12}>
            {/* <ReportTbl/> */}
            <OrderReportTbl/>
          </Grid>
        </Grid>
       
      </main>
    </div>
  );
};

export default ReportList;
