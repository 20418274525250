import { createStore } from "state-pool";

export const eventstore = createStore();
eventstore.setState("eventedit", { eventid: "" });

export const EventStore = (eventedit, data) => {
  // alert(JSON.stringify(data));
  switch (data.type) {
    case "eventedit":
      eventedit(data.data);
      //   eventedit.eventid = "1";
      return 1;
    case "cleareventedit":
      eventedit(data.data);
      //   eventedit.eventid = "1";
      break;
    default:
      break;
  }
};
