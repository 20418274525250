import { Autocomplete, Button, Checkbox, FormControlLabel, FormGroup, Grid, TextareaAutosize, TextField } from "@mui/material";
import React from "react";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DoneIcon from '@mui/icons-material/Done';
 import { Link } from "react-router-dom";
import EventTbl from "../../Table/EventTbl";
const EventList = () => {
  const Owner = [
    { label: "Wade", id: "Wade" },
    { label: "Dave", id: "Dave" },
  ];
  return (
    <div className="main-content">
      <main>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item md={12} xs={12} sm={12}>
            <h3 className="supcolor3">Event List</h3>
            <hr />
          </Grid>
          <Grid item md={12} xs={12} sm={12} >
            <Link to="/event">
            <Button
              variant="contained"
              type="button"
              style={{ float: "right"}}>New</Button>
              </Link>
             
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
           <EventTbl/>
          </Grid>
          
      
        
        </Grid>
      </main>
    </div>
  );
};

export default EventList;
