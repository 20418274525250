import React from "react";
import Footer from "../../Componants/Navbar/Footer";
import NavbarComp from "../../Componants/Navbar/NavbarComp";
import SidebarComp from "../../Componants/Sidebar/SidebarComp";
import VenuesList from "../../Componants/Venues/VenuesList";
  
const VenuesTblScreen = () => {
  return (
    <div>
      <SidebarComp />
      <NavbarComp />
     <VenuesList/>
     <Footer/>
    </div>
  );
};

export default VenuesTblScreen;
