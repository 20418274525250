import CardContent from "@mui/material/CardContent";
import { Box, Card, Typography, Grid } from "@mui/material";
import React from "react";
import Footer from "../../Componants/Navbar/Footer";
import NavbarComp from "../../Componants/Navbar/NavbarComp";
import SidebarComp from "../../Componants/Sidebar/SidebarComp";
import "../../CSS/Base.css";
 
const HomeScreen = () => {
  return (
    <div>
      <SidebarComp />
      <NavbarComp />
     
      <div className="main-content" style={{ height: "100vh" }}>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12} style={{display:"none"}}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
              }}
            >
              <div
                style={{ display: "flex", padding: "10px", fontSize: "20px" }}
              >
                <p>Sale</p>
              </div>
              <hr
                width="50%"
                style={{ border: "2px solid #022745", borderRadius: "5px" }}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  padding: "10px",
                }}
              >
                <h1>0</h1>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default HomeScreen;
