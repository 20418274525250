import {
    Autocomplete,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    TextareaAutosize,
    TextField,
  } from "@mui/material";
  import React from "react";
  import RestartAltIcon from "@mui/icons-material/RestartAlt";
  import DoneIcon from "@mui/icons-material/Done";
  import { Link } from "react-router-dom";
  import ReportTbl from "../../Table/ReportTbl";
  
import UserReportTbl from "../../Table/UserReportTbl";
  // import { CSVLink, CSVDownload } from "react-csv";
  
  // const csvData = [
  //   ["firstname", "lastname", "email"],
  //   ["Ahmed", "Tomi", "ah@smthing.co.com"],
  //   ["Raed", "Labes", "rl@smthing.co.com"],
  //   ["Yezzi", "Min l3b", "ymin@cocococo.com"]
  // ];
  // <CSVLink data={csvData}>Download me</CSVLink>;
  
  // import * as fs from 'fs'cls
  
  const UserReportList = () => {
   
  
    return (
      <div className="main-content">
        <main>
          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item md={12} xs={12} sm={12}>
              <h3 className="supcolor3">AceChaser User List</h3>
              <hr />
            </Grid>
  
            <Grid item md={12} xs={12} sm={12}>
              {/* <ReportTbl/> */}
              <UserReportTbl/>
            </Grid>
          </Grid>
         
        </main>
      </div>
    );
  };
  
  export default UserReportList;
  