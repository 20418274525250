import { Button, Grid, TextField } from '@mui/material'
import { fontSize } from '@mui/system'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetLogin, Getlogindata, Sendotp_email, updatepassword } from '../../API/LoginModel'
import { alphanumeric, genotp, onlynumeric, tostmsg } from '../../MIS/Global'
import AlertMessageComp from '../AlertMessageCom/AlertMessageComp'
import  ReactDOMServer  from 'react-dom/server'
import TestEmail from './TestEmail'
import { UserContext } from '../Context/UserContext'
import { PswStore } from '../../Reducer/ForgotPswReducer'





const ForgotPassCom = () => {
const {pswinputdisplya,setPswinputdisply} = useContext(UserContext)
const navigate =useNavigate();

const [emailid, setEmailid] = useState("");
const [passwrd, setPasswrd] = useState("");
const [cpasswrd, setCpasswrd] = useState("");
const [otp, setOtp] = useState();

const [alertdata, setAlertdata] = React.useState({})
const [alertopen, setAlertopen] = React.useState(false)

const [pswverifyotp, setPswverifyotp] = useState("");


let data = {
  emailid: emailid,
  passwrd: passwrd,
  cpasswrd: cpasswrd,
  otp: otp,
}

useEffect(() => {
  // alert(JSON.stringify(pswinputdisplya))

  }, [pswinputdisplya.verifyotp])

  return (
    <>
    <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
    <div style={{display: 'flex', justifyContent: 'center',}}>
              <Grid container spacing={1} md={4} sx={{
            display: 'flex',
            justifyContent: 'center',
            
          }}>
         <Grid  item
          xs={12}
          ms={12}
          sx={{height:'150px'}}>

          </Grid>
         <Grid
          item
          xs={12}
          ms={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: 28,
            marginTop: -5,
            marginBottom: -4,
          }}
        >
          <h3>Reset Password</h3>
        </Grid>
        <Grid  
          item
          xs={12}
          md={12}
          sx={{
            display:'flex',
            justifyContent:'center',
            fontSize:'18'
          }}>
            <span style={{margin:"10px"}}> Please Enter Your Email Address and we will Send OTP</span>
        </Grid>
        <Grid 
        item
        xs={12}
        md={12}
        sx={{
            display:'flex',
            justifyContent:'center'
        }}>
            {/* <div style={{width:'40%',}}> */}
        <TextField
              fullWidth
              id="standard-basic"
              label="Email"
              variant="standard"
              sx={{ margin: '15px'}}
              onChange={(e) => {
                setEmailid(e.target.value);
              }}
            />
            {/* </div> */}
        </Grid>
        <Grid 
        item
        xs={12}
        md={12}
        sx={{
            display:pswinputdisplya.sendotp,
            justifyContent:'center'
        }}>
            {/* <div style={{width:'40%',alignItems:'center'}}> */}
            <Button
              variant="contained"
              type="button"
              style={{ borderRadius: '20px', width: '200px',justifyContent:'center' }}
              
              onClick={() => {
                ;(async () => {
                  try {
                    let eotp= genotp;
                    setPswverifyotp(eotp)
                    const htmlEmail=ReactDOMServer.renderToStaticMarkup(<TestEmail type="" otp={eotp}/>)                    
                    // htmlEmail= htmlEmail.replace(/\\/g, "");
                    let edata={
                      emailid: emailid,
                      passwrd: passwrd,
                      cpasswrd: cpasswrd,
                      htmlmsg: `${htmlEmail.toString()}`
                    }

                    const login = await Sendotp_email(edata)
                    let rettostmsg = tostmsg(login)
                    setAlertdata(rettostmsg)
                    setAlertopen(rettostmsg.open)
                    if (login.code == 100) {
                    }

                    if (login.code == 200) {
                      setTimeout(() => {
                        PswStore(pswinputdisplya,{type:"sendotp"})
                        navigate("/forgotpass");
                      })
                    }
                    // handleSave(area);
                  } catch (e) {}
                })()
              }}
            >
              Send OTP
            </Button>
            {/* </div> */}
        </Grid>
        <Grid 
        item
        xs={12}
        md={12}
        sx={{
            display:pswinputdisplya.otp,
            justifyContent:'center'
        }}>
            {/* <div style={{width:'40%',}}> */}
        <TextField
              fullWidth
              id="standard-basic"
              label="OTP"
              variant="standard"
              sx={{ margin: '15px'}}
              inputProps={{ maxLength: 6 }}
              onChange={async (e) => {
                let retdt = await onlynumeric(e)
                setOtp(retdt)             
              }}
            />
            {/* </div> */}
        </Grid>
        <Grid  item
        xs={12}
        md={12}
        sx={{
          display:pswinputdisplya.verifyotp,
          justifyContent:'center',
            gap:'10px'

        }}>
            <Button 
             variant="contained"
              type="button"
              style={{ borderRadius: '20px', width: '200px',justifyContent:'center' 
              }}

              onClick={() => {

                (async () => {
                  try {   
                    
                                      
                      // alert(JSON.stringify(verifyotp.data))
                    
                      if (pswverifyotp!= otp) {
                        let rettostmsg = tostmsg({ msg: "Invalid OTP", code: 100 });
                        setAlertdata(rettostmsg);
                        setAlertopen(rettostmsg.open);
                        return ;
                      }else{
                      setTimeout(() => {                   
                        PswStore(pswinputdisplya,{type:"verifyotp"})
                        navigate("/forgotpass");

                        });
                      }
                    
                    // alert(JSON.stringify(emp));
                    // handleSave(area);
                  } catch (e) {}
                })();
              }}
              >
                Verify OTP
                </Button>
            <Button 
            variant="contained"
              type="button"
              style={{ borderRadius: '20px', width: '200px',justifyContent:'center'
              }}
              onClick={() => {
                ;(async () => {
                  try {

                    let eotp= genotp;
                    setPswverifyotp(eotp)
                    const htmlEmail=ReactDOMServer.renderToStaticMarkup(<TestEmail type="" otp={eotp}/>)                    
                    // htmlEmail= htmlEmail.replace(/\\/g, "");
                    let edata={
                      emailid: emailid,
                      passwrd: passwrd,
                      cpasswrd: cpasswrd,
                      htmlmsg: `${htmlEmail.toString()}`
                    }

                    const login = await Sendotp_email(edata)
                    let rettostmsg = tostmsg(login)
                    setAlertdata(rettostmsg)
                    setAlertopen(rettostmsg.open)
                    if (login.code == 100) {
                    }

                    if (login.code == 200) {
                      setTimeout(() => {
                        // PswStore(pswinputdisplya,{type:"sendotp"})
                        // navigate("/forgotpass");
                      })
                    }
                    // handleSave(area);
                  } catch (e) {}
                })()
              }}
              >
                Resend OTP
                </Button>
        </Grid>
        <Grid 
        item
        xs={12}
        md={12}
        sx={{
          display:pswinputdisplya.password,
          justifyContent:'center'
        }}>
            {/* <div style={{width:'40%',}}> */}
        <TextField
              fullWidth
              id="standard-basic"
              label="New Password"
              type="password"
              variant="standard"
              sx={{ margin: '15px'}}
              value={passwrd}
              onChange={async (e) => {
                let retdt = await alphanumeric(e)
                setPasswrd(retdt)             
              }}
            />
            {/* </div> */}
        </Grid>
        <Grid 
        item
        xs={12}
        md={12}
        sx={{
          display:pswinputdisplya.cpassword,
          justifyContent:'center'
        }}>
            {/* <div style={{width:'40%',}}> */}
        <TextField
              fullWidth
              id="standard-basic"
              type="password"
              label="Confirm Password"
              variant="standard"
              value={cpasswrd}
              sx={{ margin: '15px'}}
              onChange={async (e) => {
                let retdt = await alphanumeric(e)
                setCpasswrd(retdt)             
              }}
            />
            {/* </div> */}
        </Grid>
        <Grid  item
        xs={12}
        md={12}
        sx={{
          display:pswinputdisplya.submit,
          justifyContent:'center',
           

        }}>
            <Button 
             variant="contained"
              type="button"
              style={{ borderRadius: '20px', width: '200px',justifyContent:'center' 
              }}
              onClick={() => {
                (async () => {
                  try {
                    const login = await updatepassword(data);
                    let rettostmsg = tostmsg(login);
                    setAlertdata(rettostmsg);
                    setAlertopen(rettostmsg.open);
                    if (login.code == 100) {
                    }

                    if (login.code == 200) {
                      // alert(login.msg);
                      setTimeout(() => {
                       navigate("/");
                      });
                    }
                    // alert(JSON.stringify(emp));
                    // handleSave(area);
                  } catch (e) {}
                })();
              }}
              >
                Submit
                </Button>
            
        </Grid>
           

            </Grid>
    </div>
    </>
  )
}

export default ForgotPassCom