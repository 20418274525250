import { nanoid } from "nanoid";

 
export const LogindataReducer=(logindata, action)=> {
    switch (action.type) {
      case 'added': {
        return [
          {
            logid: action.logid,
            uname: action.uname,
            emailid: action.emailid,
          },
        ];
      }
      case 'clear': {
        return [
            {
              logid:0,
              uname:"",
              emailid:"",
            },
          ];
      }
     
      default: {
        throw Error('Unknown action: ' + action.type);
      }
    }
  }

  export const LogdataReducer=(logdata, action)=> {
    switch (action.type) {
      case 'add': {
        return [
          action.data,
        ];
      }
      case 'clear': {
        return [];
      }      
      default: {
        throw Error('Unknown action: ' + action.type);
      }
    }
  }