import { BASE_URL } from "../MIS/Global";
import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import Loader from "../Componants/loader/Loader";


export const GetUserLogin = async (data) => {
    let ret = {};
    var config = {
      method: "post",
      url: BASE_URL + "getuserlogin",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));
  
    await axios(config)
      .then(function (response) {
        ReactDOM.render(
          <Loader bal={false}></Loader>,
          document.getElementById("root1")
        );
        ret = response.data;
        if (ret.code == 200) {
          ret = { msg: ret.msg, code: 200, data: ret.data };
          return ret;
        } else if (ret.code == 300) {
          ret = { msg: "Invalid Email/Password", code: 100 };
          return ret;
        } else if (ret.code == 400) {
          ret = { msg: ret.msg, code: 400 };
          return ret;
        } else {
          ret = { msg: "something went wrong! please try again ", code: 500 };
          return ret;
        }
      })
      .catch(function (error) {
        ReactDOM.render(
          <Loader bal={false}></Loader>,
          document.getElementById("root1")
        );
        ret = { msg: "something went wrong! please try again ", code: 500 };
        return ret;
      });
    return ret;
  };