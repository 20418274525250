import { BASE_URL } from "../MIS/Global";
import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import Loader from "../Componants/loader/Loader";

export const SaveEvent = async (data) => {
  //   alert(JSON.stringify(data));

  if (data.type.length <= 0) {
    return { msg: "Enter Type Name", code: 100 };
  }
  if (data.eventname.length <= 0) {
    return { msg: "Enter Event Name", code: 100 };
  }
  ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));

  let url = "saveevent";
  if (data.eventid > 0) {
    url = "updateevent";
  }
  let ret = {};
  var config = {
    method: "post",
    url: BASE_URL + url,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  await axios(config)
    .then(function (response) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = response.data;
      if (ret.code == 200) {
        ret = { msg: ret.msg, code: 200, data: ret.data };
        return ret;
      } else if (ret.code == 400) {
        ret = { msg: ret.msg, code: 400 };
        return ret;
      } else {
        ret = { msg: "something went wrong! please try again1 ", code: 500 };

        return ret;
      }
    })
    .catch(function (error) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = { msg: error, code: 500 };

      return ret;
    });

  return ret;
};

export const GetEvent = async (data) => {
  let ret = {};
  var config = {
    method: "post",
    url: BASE_URL + "getevent",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));

  await axios(config)
    .then(function (response) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = response.data;
      if (ret.code == 200) {
        ret = { msg: ret.msg, code: 200, data: ret.data };
        return ret;
      } else if (ret.code == 400) {
        ret = { msg: ret.msg, code: 400 };
        return ret;
      } else {
        ret = { msg: "something went wrong! please try again ", code: 500 };
        return ret;
      }
    })
    .catch(function (error) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = { msg: "something went wrong! please try again ", code: 500 };
      return ret;
    });
  return ret;
};

export const DeleteEvent = async (data) => {
  let ret = {};
  var config = {
    method: "post",
    url: BASE_URL + "deleteevent",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));

  await axios(config)
    .then(function (response) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = response.data;
      if (ret.code == 200) {
        ret = { msg: ret.msg, code: 200, data: ret.data };
        return ret;
      } else if (ret.code == 400) {
        ret = { msg: ret.msg, code: 400 };
        return ret;
      } else {
        ret = { msg: "something went wrong! please try again ", code: 500 };
        return ret;
      }
    })
    .catch(function (error) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = { msg: "something went wrong! please try again ", code: 500 };
      return ret;
    });
  return ret;
};
