import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Grid } from "@mui/material";
import CollectionsIcon from "@mui/icons-material/Collections";
import { ShowStore } from "../Context/ShowReducer";
import { UserContext } from "../Context/UserContext";
import Compress from "react-image-file-resizer";
import defimg from '../../Image/default-banner.jpg'


function FileUpload(props) {
  const { showedit, setShowedit } = useContext(UserContext);
  const [file, setFile] = useState(""); // storing the uploaded file
  const [path, setPath] = useState(""); // storing the uploaded file
  const [path1, setPath1] = useState(""); // storing the uploaded file

  // storing the recived file from backend
  const [data, getFile] = useState({ name: "", path: "" });
  const [progress, setProgess] = useState(0); // progess bar
  const el = useRef(); // accesing input element
  const refinp_file = useRef();
  var xhr = new XMLHttpRequest();
  const handleChange = (e) => {
    setProgess(0);
    const file = e.target.files[0]; // accesing file
    setFile(file); // storing file
    const exten=file.type.split("/");
    Compress.imageFileResizer(
      file, // the file from input
      "100%", // width
      "300px", // height
      exten[1], // compress format WEBP, JPEG, PNG
      100, // quality
      0, // rotation
      (uri) => {
        props.setBlob(uri);
        // You upload logic goes here
      },
      "base64" // blob or base64 default base64
    );
    let reader = new FileReader();

    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (e) => {
      //console.log(e.target.result);
      setPath(e.target.result);

      // alert(window.dataURLtoBlob(reader.result));
      // Blob_dataUrl(blob);
    };
    ShowStore(setShowedit, {
      type: "setimgdata",
      data: { path },
    });
    // alert(JSON.stringify(formData));
  };

  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  const Blob_dataUrl = (blobdata) => {
    var blob = new Blob([blobdata], { type: blobdata.type });
    var blobUrl = URL.createObjectURL(blob);

    xhr.responseType = "blob";
    var blobAsDataUrl = "";
    xhr.onload = function () {
      var recoveredBlob = xhr.response;

      var reader = new FileReader();

      reader.onload = function () {
        blobAsDataUrl = reader.result;
        // alert(blobAsDataUrl);
        setPath(blobAsDataUrl);
        // window.location = blobAsDataUrl;
      };

      reader.readAsDataURL(recoveredBlob);
    };

    xhr.open("GET", blobUrl);
    xhr.send(null);
  };

  const uploadFile = () => {
    const formData = new FormData();
    formData.append("file", file); // appending file
    axios
      .post("http://localhost:4000/api/ShowFileUpload", formData, {
        onUploadProgress: (ProgressEvent) => {
          let progress =
            Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) +
            "%";
          setProgess(progress);
        },
      })
      .then((res) => {
        console.log(res);
        alert(JSON.stringify(res));
        getFile({
          name: res.data.name,
          path: "http://localhost:4000/uploads/Show/" + res.data.path,
        });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setPath(props.blob);
  }, []);

  const showResult = (path) => {
      if(path=="-"){path=defimg}
    if (path)
      switch (path.split(".")[1]) {
        case "jpg":
        case "jpeg":
        case "png":
        case "bmp":
        case "gif":
          return (
            <img
              src={path}
              alt="img data"
              style={{ width: "100%", height: "200px", objectFit: "fill" }}
            />
          );
        case "mp4":
        case "mpg":
        case "webm":
        case "mov":
        case "3gp":
          return <video src={path} autoPlay controls />;
        default:
          return (
            <>
              <img
                src={path}
                alt="img data"
                style={{ width: "100%", height: "300px", objectFit: "fill" }}
              />
            </>
          );
      }
  };
  const addImage = () => {
    refinp_file.current.click();
  };

  return (
    <Grid container spacing={2} sx={{ display: "flex" }}>
      <Grid item md={4} xs={12}>
        <div
          className=""
          style={{
            borderColor: "gray",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "self-start",
            backgroundColor: "#d1e8f8",
            borderRadius: "10px",
          }}
        >
          <input
            ref={refinp_file}
            type="file"
            style={{ display: "none" }}
            onChange={handleChange}
          />
          <button
            onClick={() => addImage()}
            type="button"
            style={{
              height: "10vh",
              width: "100%",
              background: "none",
              cursor: "pointer",
              fontSize: "20px",
              border: "none",
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>
                <CollectionsIcon style={{ marginRight: "10px" }} />
              </span>
              Draw Image
            </span>
          </button>
        </div>
      </Grid>
      <Grid item md={10} xs={12}>
        {showResult(path)}
      </Grid>

      {/* <div className="progessBar" style={{ width: progress }}>
        {progress}
      </div>
      <button onClick={uploadFile} className="upbutton">
        Upload
      </button>
      <hr />
      {showResult(data.path)}
    </div> */}
    </Grid>
  );
}
export default FileUpload;
