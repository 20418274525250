import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DoneIcon from "@mui/icons-material/Done";
import { SaveVendor } from "../../API/VendorModel";
import { alphanumeric, tostmsg } from "../../MIS/Global";
import { useNavigate } from "react-router-dom";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import { VendorStore } from "../Context/VendorReducer";
import { useContext } from "react";
import { UserContext } from "../Context/UserContext";
import OwnernameSuggest from "../../Textsuggestfield/OwnernameSuggest";
import TextSuggestProvience from "../../Textsuggestfield/TextSuggestProvience";

const Vendor = () => {
  const { vendoredit, setVendoredit,formname, setFormname } = useContext(UserContext);
  const [vendrid, setVendrid] = useState(vendoredit[0]?.vendrid);
  const [vendrname, setVendrname] = useState(vendoredit[0]?.vendrname || "");
  let ownerdet="";
  if(vendoredit[0]?.uname!=undefined){
      ownerdet=vendoredit[0]?.uname+"-"+vendoredit[0]?.phone+" <"+vendoredit[0]?.emailid+">";
  }
  let ownerdet1="";
  if(vendoredit[0]?.uname1!=undefined){
      ownerdet1=vendoredit[0]?.uname1+"-"+vendoredit[0]?.phone1+" <"+vendoredit[0]?.emailid1+">" ;
  }
  const [owner, setOwner] = useState(ownerdet || "");
  const [ownerid, setOwnerid] = useState(vendoredit[0]?.owner||0);
  const [owner1, setOwner1] = useState(ownerdet1|| "");
  const [ownerid1, setOwnerid1] = useState(vendoredit[0]?.owner1||0);
  const [chasetheace, setChasetheace] = useState(
    vendoredit[0]?.chasetheace || true
  );
  const [descrp, setDescrp] = useState(vendoredit[0]?.descrp || "-");
  const [address, setAddress] = useState(vendoredit[0]?.address || "");
  const [pincode, setPincode] = useState(vendoredit[0]?.pincode || "");
  const [country, setCountry] = useState(vendoredit[0]?.country || "Canada");
  const [state, setState] = useState(vendoredit[0]?.state || "Prince Edward Island");
  const [city, setCity] = useState(vendoredit[0]?.city || "");
  const [primaryperson,setPrimaryPerson]=useState(vendoredit[0]?.primaryperson || "");
  // Prince Edward Island
  const [isstatus, setIsstatus] = useState(
    vendoredit[0]?.isstatus || "Deactive"
  );
  const [uid, setUid] = useState(vendoredit[0]?.uid || 0);
  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);
  const navigate = useNavigate();

 

  let data = {
    vendrid: vendrid,
    vendrname: vendrname,
    owner: ownerid,
    chasetheace: chasetheace,
    descrp: descrp,
    isstatus: isstatus,
    uid: uid,
    owner1: ownerid1,
    pincode:pincode,
    address:address,
    city:city,
    province:state,
    country:country,
    primaryperson:primaryperson,
  };
  return (
    <div className="main-content">
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <main>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item md={12} xs={8} sm={6}>
            <h3 className="supcolor3">{formname||"Add New Committee"}</h3>
            <hr/>
          </Grid>
          <Grid item md={6} xs={12} sm={12}>
            <TextField
              autoFocus
              size="small"
              id="outlined-case no-input"
              fullWidth
              value={vendrname}
              label="Committee Name"
              inputProps={{ maxLength: 100 }}
              onChange={async (e) => {
                // let retdt = await alphanumeric(e);                
                setVendrname(e.target.value);
              }}
            />
          </Grid>
          <Grid item md={6} xs={12} sm={12}>
            <TextField
              size="small"              
              fullWidth              
              value={primaryperson}
              label="Primary Contact Person"
              inputProps={{ maxLength: 250 }}              
              onChange={async (e) => {
                let retdt = await alphanumeric(e);
                setPrimaryPerson(retdt);
              }}
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <TextField
              autoFocus
              size="small"
              id="outlined-case no-input"
              fullWidth
              value={address}
              label="Draw Address"
              inputProps={{ maxLength: 150 }}
              onChange={async (e) => {
                let retdt = await alphanumeric(e);
                setAddress(retdt);
              }}
            />
          </Grid>
          <Grid item md={6} xs={12} sm={12} sx={{display:"none"}}>
            <OwnernameSuggest
              lblname="Primary Contact Person"
              setUname={setOwner}
              uname={owner}
              setLogid={setOwnerid}
            />
          </Grid>
          <Grid item md={6} xs={12} sm={12} sx={{display:"none"}}>
            <OwnernameSuggest
              lblname="Secondary Contact Person"
              setUname={setOwner1}
              uname={owner1}
              setLogid={setOwnerid1}
            />
          </Grid>
          <Grid item md={4} xs={12} sm={12} sx={{display:"none"}}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked disabled />}
                label="Chase The Ace"
              />
            </FormGroup>
          </Grid>
          <Grid item md={12} xs={12} sm={12} sx={{display:"none"}}>
            <TextareaAutosize
              minRows={3}
              placeholder="Description"
              style={{ width: "100%",padding:"5px" }}
              value={descrp}
              onChange={async (e) => {
                // let retdt = await alphanumeric(e);
                setDescrp(e.target.value);
              }}
            />
          </Grid>
          <Grid item md={3} xs={12} sm={12}>
            <TextField
              size="small"
              id="outlined-case no-input"
              fullWidth
              label="City"
              value={city}
              inputProps={{ maxLength: 20 }}
              onChange={async (e) => {
                let retdt = await alphanumeric(e);
                setCity(retdt);
              }}
            />
          </Grid>
          <Grid item md={3} xs={12} sm={12}>
            <TextField
              size="small"
              id="outlined-case no-input"
              fullWidth
              value={pincode}
              label="Postal Code"
              inputProps={{ maxLength: 7 }}
              onChange={async (e) => {
                let retdt = await alphanumeric(e);
                setPincode(retdt);
              }}
            />
          </Grid>
          <Grid item md={3} xs={12} sm={12}>
            <TextSuggestProvience state={state} setState={setState} />
          </Grid>
          <Grid item md={3} xs={12} sm={12}>
            <TextField
              size="small"
              id="outlined-case no-input"
              fullWidth
              disabled
              value="Canada"
              label="Country"
              inputProps={{ maxLength: 20 }}
              onChange={async (e) => {
                let retdt = await alphanumeric(e);
                setCountry(retdt);
              }}
            />
          </Grid>
          
          <Grid item md={12} xs={12} sm={12}>
            <Button
              variant="contained"
              type="button"
              style={{ float: "right" }}
              onClick={() => {
                (async () => {
                  try {
                    const vendor = await SaveVendor(data);
                    // alert();

                    let rettostmsg = tostmsg(vendor);
                    // alert(JSON.stringify(rettostmsg));
                    setAlertdata(rettostmsg);
                    setAlertopen(rettostmsg.open);
                    if (vendor.code == 100) {
                    }

                    if (vendor.code == 200) {
                      setTimeout(() => {
                        VendorStore(setVendoredit, {
                          type: "clearvendoredit",
                          data: {},
                        });
                        navigate("/vendorlist");
                      });
                    }
                  } catch (e) {}
                })();
              }}
            >
              <DoneIcon /> Submit
            </Button>
            <Button
              variant="contained"
              type="button"
              className="primcolor1_bg"
              style={{
                float: "right",
                backgroundColor: "#f3797e",
                marginRight: "10px",
              }}
              onClick={()=>{
                
                window.location.reload()
              }}
            >
              <RestartAltIcon /> Reset
            </Button>
            <Button
              variant="contained"
              type="button"
              className="primcolor1_bg"
              style={{
                float: "right",
                backgroundColor: "#555555",
                marginRight: "10px",
              }}
              onClick={()=>{navigate("/vendorlist")}}
            >
             Back To Draw Committee List
            </Button>
          </Grid>
        </Grid>
      </main>
    </div>
  );
};

export default Vendor;
