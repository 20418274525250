import React from "react";
import AdminUser from "../../Componants/AdminUser/AdminUser";
 import Footer from "../../Componants/Navbar/Footer";
import NavbarComp from "../../Componants/Navbar/NavbarComp";
 import SidebarComp from "../../Componants/Sidebar/SidebarComp";
  
const AdminUserScreen = () => {
  return (
    <div>
      <SidebarComp />
      <NavbarComp />
     <AdminUser/>
     <Footer/>
    </div>
  );
};

export default AdminUserScreen;
