import { BASE_URL } from "../MIS/Global";
import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import Loader from "../Componants/loader/Loader";

export const SaveVendor = async (data) => {
   const postalCodePattern = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/;                               
  if (data.vendrname.length <= 0) {
    return { msg: "Enter Vendor Name", code: 100 };
  }
  if (data.address.length <= 0) {
    return { msg: "Enter Draw Address", code: 100 };
  }
  if (data.city.length <= 0) {
    return { msg: "Enter City", code: 100 };
  }
  if(data.pincode.length<6){
    return { msg: "Enter Valid Postal Code", code: 100 };
  }
  if(postalCodePattern.test(data?.pincode)==false){
    return { msg: "Enter Valid Postal Code (K1A 0B1)", code: 100 };
  }
  // if (data.owner <= 0) {
  //   return { msg: "Enter Owner Name", code: 100 };
  // }
  // if (data.owner1 <= 0) {
  //   return { msg: "Enter Owner Name", code: 100 };
  // }
   ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));

  let url = "savevendor";
  if (data.vendrid > 0) {
    url = "updatevendor";
  }
  let ret = {};
  var config = {
    method: "post",
    url: BASE_URL + url,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  await axios(config)
    .then(function (response) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = response.data;
       if (ret.code == 200) {
        ret = { msg: ret.msg, code: 200, data: ret.data };
        return ret;
      }else if (ret.code == 100) {
        ret = { msg: ret.msg, code: 100 };
        return ret;
      } else if (ret.code == 400) {
        ret = { msg: ret.msg, code: 400 };
        return ret;
      } else {
        ret = { msg: "something went wrong! please try again ", code: 500 };

        return ret;
      }
    })
    .catch(function (error) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = { msg: error, code: 500 };

      return ret;
    });

  return ret;
};

export const UpdateVendorStatus = async (data) => {
  // alert(JSON.stringify(data));
  ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));
  let ret = {};
  var config = {
    method: "post",
    url: BASE_URL + "updatestatus",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  await axios(config)
    .then(function (response) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = response.data;

      if (ret.code == 200) {
        ret = { msg: ret.msg, code: 200, data: ret.data };
        return ret;
      } else if (ret.code == 400) {
        ret = { msg: ret.msg, code: 400 };
        return ret;
      } else {
        ret = { msg: "something went wrong! please try again ", code: 500 };

        return ret;
      }
    })
    .catch(function (error) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = { msg: error, code: 500 };

      return ret;
    });

  return ret;
};

export const GetVender = async (data) => {
  let ret = {};
  var config = {
    method: "post",
    url: BASE_URL + "getvendor",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));

  await axios(config)
    .then(function (response) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = response.data;
      if (ret.code == 200) {
        ret = { msg: ret.msg, code: 200, data: ret.data };
        return ret;
      } else if (ret.code == 400) {
        ret = { msg: ret.msg, code: 400 };
        return ret;
      } else {
        ret = { msg: "something went wrong! please try again ", code: 500 };
        return ret;
      }
    })
    .catch(function (error) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = { msg: "something went wrong! please try again ", code: 500 };
      return ret;
    });
  return ret;
};

export const DeleteVendor = async (data) => {
  let ret = {};
  var config = {
    method: "post",
    url: BASE_URL + "deletevendor",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));

  await axios(config)
    .then(function (response) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = response.data;
      if (ret.code == 200) {
        ret = { msg: ret.msg, code: 200, data: ret.data };
        return ret;
      } else if (ret.code == 400) {
        ret = { msg: ret.msg, code: 400 };
        return ret;
      } else {
        if(ret?.err?.errno==1451){          
          ret = { msg: "This committee exist in draw", code: 500 };
          return ret;
        }
        ret = { msg: "something went wrong! please try again ", code: 500 };
        return ret;
      }
    })
    .catch(function (error) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = { msg: "something went wrong! please try again ", code: 500 };
      return ret;
    });
  return ret;
};

export const DeleteCollectionImg = async (data) => {
  let ret = {};
  var config = {
    method: "post",
    url: BASE_URL + "imgdeletecollection",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));

  await axios(config)
    .then(function (response) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = response.data;
      if (ret.code == 200) {
        ret = { msg: ret.msg, code: 200, data: ret.data };
        return ret;
      } else if (ret.code == 400) {
        ret = { msg: ret.msg, code: 400 };
        return ret;
      } else {
        ret = { msg: "something went wrong! please try again ", code: 500 };
        return ret;
      }
    })
    .catch(function (error) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = { msg: "something went wrong! please try again ", code: 500 };
      return ret;
    });
  return ret;
};
