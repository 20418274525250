import { createStore } from "state-pool";

export const showstore = createStore();
showstore.setState("showedit", {});

export const ShowStore = (showedit, data) => {
  //   alert(JSON.stringify(data));
  switch (data.type) {
    case "showedit":
      showedit(data.data);
      //   eventedit.eventid = "1";
      return 1;
    case "cleareventedit":
      showedit(data.data);
      //   eventedit.eventid = "1";
      break;
    case "setimgdata":
      showedit(data.data);
      //   eventedit.eventid = "1";
      break;
    default:
      break;
  }
};
