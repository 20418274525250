import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Autocomplete, Button, TextField, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import ListIcon from "@mui/icons-material/List";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
 
import {
  DeleteVendor,
  GetVender,
  UpdateVendorStatus,
} from "../API/VendorModel";
import { tostmsg } from "../MIS/Global";
import AlertMessageComp from "../Componants/AlertMessageCom/AlertMessageComp";
import { useContext } from "react";
import { VendorStore } from "../Componants/Context/VendorReducer";
import { UserContext } from "../Componants/Context/UserContext";

const columns = [
  { label: "Committee Name", minWidth: "30%" },  
  { label: "Committee Address", minWidth: "20%" },
  { label: "Primary Contact Person", minWidth: "25%" },
  { label: "Status", minWidth: "15%" },
  { label: "Action", minWidth: "10%" },
];

function createData(
  vendrid,
  vendrname,
  venuname,
  owner,
  isstatus,
  chasetheace,
  descrp,
  uid,
  contact1,
  address,
  search
) {
  return {
    vendrid,
    vendrname,
    venuname,
    owner,
    isstatus,
    chasetheace,
    descrp,
    uid,
    contact1,
    address,
    search,
  };
}

export default function VendorTbl(props) {
  const [rows, setRows] = useState([]);
  const [srows, setSrows] = useState([]);
  const [searched, setSearched] = useState("");
  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);

  const { navigate, vendoredit, setVendoredit,formname, setFormname } = useContext(UserContext);
  let tabledata = {};

  const UpdateStatus = async (id, isstatus) => {
    let vendor = await UpdateVendorStatus({ vendrid: id, isstatus: isstatus });
    return vendor;
  };
  const getEditdata = async (id) => {
    tabledata = await GetVender({ vendrid: id });
    // alert(JSON.stringify(tabledata));
    let x = VendorStore(setVendoredit, {
      type: "vendoredit",
      data: tabledata.data,
    });
    return x;
  };

  const getData = async () => {
    setRows([]);
    setSrows([]);
    // let data = { fdate: monday, tdate: saturday };
    tabledata = await GetVender();

    // alert(JSON.stringify(tabledata.data));
    const arr = [];
    tabledata.data.forEach((element) => {
      let search = element.vendrname+"-"+element.vendrname+"-"+element.address+"-"+element.primaryperson+"-"+element.isstatus;      
      arr.push(
        createData(
          element.vendrid,
          element.vendrname,
          element.venuname,
          element.owner,
          element.isstatus,    
          "",
          "",
          "",
          element.primaryperson,
          element.address,
          search
        )
      );
      setRows([...arr]);
      setSrows([...arr]);
    });
  };

  useEffect(() => {
    getData();
  }, [props.searchdata]);

  const requestSearch = (searchedVal, srows) => {
    const filteredRows = srows.filter((row) => {
      return row.search.toLowerCase().includes(searchedVal.toLowerCase());
    });
    return filteredRows;
  };

  const search = (searchedVal) => {
    let filteredRows = requestSearch(searchedVal, srows);
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const Owner = [
    { label: "Active", id: "Active" },
    { label: "Deactive", id: "Deactive" },
  ];

  return (
    <div className="">
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <Paper sx={{ width: "100%" }}>
        <TextField
          placeholder="search"
          fullWidth
          className="searchbar"
          onChange={(searchVal) => search(searchVal.target.value)}
        />

        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ fontSize: "100pt!important" }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ width: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow style={{ cursor: "pointer" }} hover tabIndex={-1}>
                      <TableCell sx={{display:""}}>{row.vendrname}</TableCell>
                      <TableCell sx={{display:"none"}}>
                        <div>{row.venuname}</div>
                        <div
                          className="supcolor3"
                          style={{
                            display: "none",
                            alignItems: "center",
                            fontWeight: "bolder",
                            justifyContent: "center",
                          }}
                        >
                          <Link
                            to="/venueslist"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "bolder",
                              justifyContent: "center",
                            }}
                          >
                            {" "}
                            <ListIcon></ListIcon>All Draw Address
                          </Link>
                          <Link
                            to="/venues"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "bolder",
                              justifyContent: "center",
                            }}
                          >
                            <AddIcon></AddIcon>Add Draw Address
                          </Link>
                        </div>
                      </TableCell>
                      <TableCell sx={{display:""}}>{row.address}</TableCell>
                      <TableCell sx={{display:""}}>{row.contact1?row.contact1:"-"}</TableCell>                     
                      <TableCell sx={{display:"none"}}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <PersonIcon></PersonIcon>
                          {/* {row.owner} */}
                          {"Keith Gearing <kgearing@ticketwizard.ca>"}
                        </div>
                        {/* <div style={{ display: "flex", alignItems: "center" }}>
                          <EmailIcon></EmailIcon> kgearing@ticketwizard.ca
                        </div> */}
                      </TableCell>
                      <TableCell>
                        <Autocomplete
                          disablePortal
                          disableClearable
                          style={{ backgroundColor: "tomato" }}
                          id="combo-box-demo"
                          size="small"
                          value={row.isstatus}
                          options={Owner}
                          onChange={(e, newValue) => {
                            (async () => {
                              try {
                                // setOwner(newValue.label);
                                let vendor = await UpdateStatus(
                                  row.vendrid,
                                  newValue.label
                                );
                                let rettostmsg = tostmsg(vendor);
                                // alert(JSON.stringify(venu));
                                setAlertdata(rettostmsg);
                                setAlertopen(rettostmsg.open);
                                getData();
                              } catch (e) {}
                            })();
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </TableCell>
                      <TableCell style={{ color: "#7978e9",display:"flex",gap:"10px" }}>
                      <Tooltip title="Edit" placement="top">   
                        <EditIcon
                          onClick={() => {
                            (async () => {
                              try {
                                setFormname("Edit Committee")
                                let ret = await getEditdata(row.vendrid);
                                setTimeout(() => {
                                  navigate("/vendor");
                                }, 1000);
                              } catch (e) {}
                            })();
                          }}
                        ></EditIcon>
                        </Tooltip>
                        <Tooltip title="Delete" placement="top">   
                        <DeleteForeverIcon
                          style={{ color: "red",fontSize:"25px" }}
                          onClick={() => {
                            (async () => {
                              try {
                                // alert(row.bnrid);
                                if (
                                  window.confirm(
                                    "Are You Sure....delete this record"
                                  ) == false
                                ) {
                                  return;
                                }
                                const bannerdelete = await DeleteVendor({
                                  vendrid: row.vendrid,
                                });
                                let rettostmsg = tostmsg(bannerdelete);
                                setAlertdata(rettostmsg);
                                setAlertopen(rettostmsg.open);
                                if (bannerdelete.code == 100) {
                                  // window.location.reload();
                                  getData();
                                }
                                if (bannerdelete.code == 200) {
                                  // alert(brand.msg);
                                  setTimeout(() => {
                                    // window.location.reload();
                                    getData();
                                  });
                                } else {
                                  // alert(categorydelete.msg);
                                }
                              } catch (e) {}
                            })();
                          }}
                        />
                        </Tooltip>
                      </TableCell>
                      {/* AddIcon  
                        ListIcon  
                        PictureAsPdfIcon  
                        SavedSearchIcon   */}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[100, 250, 500, 1000]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
