import "./App.css";
import FileUpload from "./Componants/FileUplode/FileUplode";
import Login from "./Componants/Login/Login";
import Aboutus from "./Componants/Navbar/Aboutus";
import ClientNavBar from "./Componants/Navbar/ClientNavBar";
function App() {
  return (
    <>
      {/* <FileUpload /> */}
      <Login />
      {/* <ClientNavBar/>
    <br/>   <br/>  <br/> 
    <Aboutus/> */}
    </>
  );
}

export default App;
