import React from "react";
import Footer from "../../Componants/Navbar/Footer";
import NavbarComp from "../../Componants/Navbar/NavbarComp";
 import SidebarComp from "../../Componants/Sidebar/SidebarComp";
import Vendor from "../../Componants/Vendor/Vendor";
 
const VendorScreen = () => {
  return (
    <div>
      <SidebarComp />
      <NavbarComp />
     <Vendor/>
     <Footer/>
    </div>
  );
};

export default VendorScreen;
