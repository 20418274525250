import { nanoid } from "nanoid";

 
export const TicketReducer=(ticket, action)=> {
    switch (action.type) {
      case 'added': {
        return [
          ...ticket,
          {
            id: nanoid(),
            text: action.text,
            qty: action.qty,
            price:action.price
          },
        ];
      }
      case 'changed': {
        return ticket.map((t) => {
          if (t.id === action.ticket.id) {
            return action.ticket;
          } else {
            return t;
          }
        });
      }
      case 'deleted': {
        return ticket.filter((t) => t.id !== action.id);
      }
      default: {
        throw Error('Unknown action: ' + action.type);
      }
    }
  }