import React from "react";
import Event from "../../Componants/Event/Event";
import Footer from "../../Componants/Navbar/Footer";
import NavbarComp from "../../Componants/Navbar/NavbarComp";
 import SidebarComp from "../../Componants/Sidebar/SidebarComp";
  
const EventScreen = () => {
  return (
    <div>
      <SidebarComp />
      <NavbarComp />
     <Event/>
     <Footer/>
    </div>
  );
};

export default EventScreen;
