import { createStore } from "state-pool";

export const vendorstore = createStore();
vendorstore.setState("vendoredit", { vendrid: "" });

export const VendorStore = (vendoredit, data) => {
  //   alert(JSON.stringify(data));
  switch (data.type) {
    case "vendoredit":
      vendoredit(data.data);
      //   eventedit.eventid = "1";
      return 1;
    case "clearvendoredit":
      vendoredit(data.data);
      //   eventedit.eventid = "1";
      break;
    default:
      break;
  }
};
