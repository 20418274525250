import React, { useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GetLogin } from "../../API/LoginModel";
import { tostmsg } from "../../MIS/Global";
import { LogindataReducer } from "../../Reducers/LogindataReducer";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import "../Login/login.css";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
const Login = () => {
  const [email, setEmail] = React.useState("");
  const [pass, setPass] = React.useState("");
  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);
  const [showpaswd, setShowpaswd] = React.useState(false);
  const navigate = useNavigate();
  const [logindata, logindata_dispatch] = useReducer(LogindataReducer);

  let data = {
    emailid: email,
    passwrd: pass,
  };

  const handleAddTicket = () => {
    let validRet = {};
  };
  return (
    <main className="main" style={{ backgroundColor: "#022745" }}>
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <div
        style={{
          color: "#fff",
          display: "flex",
          justifyContent: "center",
          padding: "10px",
          fontSize: "40px",
        }}
      >
        AceChaser
      </div>
      <div className="wrapper">
        <div className="heading">
          <h1>Login</h1>
        </div>

        <div action="#" className="form-group">
          <input
            type="text"
            placeholder="email"
            value={email}
            className="email"
            autoFocus
            onChange={async (e) => {
              setEmail(e.target.value);
            }}
          />
          <div style={{ position: "relative" }} className="form-group">            
            <input
              type= {showpaswd==false?"password":"text"}
              placeholder="password"
              value={pass}
              className="passwd"
              onChange={async (e) => {
                setPass(e.target.value);
              }}
            />
            <div
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor:"pointer"
              }}
              onClick={()=>{setShowpaswd(!showpaswd)}}
            >
              {
                showpaswd==false?<VisibilityOffIcon/>:<VisibilityIcon/>
              }  
             </div>
          </div>
          <a
            href="#"
            className="btn btn-forget"
            onClick={() => {
              window.location.href = "/forgotpass";
            }}
          >
            Forget your password?
          </a>
          <button
            className="btn btn-primary"
            onClick={() => {
              (async () => {
                try {
                  let rettostmsg = "";
                  if (data.emailid.length <= 0) {
                    rettostmsg = tostmsg({ msg: "Enter Email Id", code: 100 });
                    setAlertdata(rettostmsg);
                    setAlertopen(rettostmsg.open);
                    return;
                  }
                  if (data.passwrd.length <= 0) {
                    rettostmsg = tostmsg({ msg: "Enter Password", code: 100 });
                    setAlertdata(rettostmsg);
                    setAlertopen(rettostmsg.open);
                    return;
                  }

                  const user = await GetLogin(data);
                  rettostmsg = tostmsg(user);
                  // alert(JSON.stringify(user));
                  setAlertdata(rettostmsg);
                  setAlertopen(rettostmsg.open);

                  if (user.code == 100) {
                  }

                  if (user.code == 200) {
                    // alert(user.data[0].emailid)

                    setTimeout(() => {
                      navigate("/home");
                    });
                    sessionStorage.setItem("logid", user.data[0].logid);
                    // logindata_dispatch({
                    //   type: "added",
                    //   logid: user.data[0].logid,
                    //   uname: user.data[0].uname,
                    //   emailid: user.data[0].emailid,
                    // });
                  }
                } catch (e) {}
              })();
            }}
          >
            log in
          </button>
        </div>
      </div>
    </main>
  );
};

export default Login;
