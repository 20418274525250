import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DoneIcon from "@mui/icons-material/Done";
import { alphanumeric, onlynumeric, tostmsg } from "../../MIS/Global";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import { useNavigate } from "react-router-dom";
import { GetLogin, SaveLogin } from "../../API/LoginModel";
import { UserContext } from "../Context/UserContext";
import { LoginStore } from "../Context/LoginReducer";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { InputAdornment } from "@material-ui/core";

const AdminUser = () => {
  const { loginedit, setLoginedit } = useContext(UserContext);
  const [logid, setLogid] = useState(loginedit[0]?.logid);
  const [refid, setTefid] = useState(loginedit[0]?.refid || 0);
  const [uname, setUname] = useState(loginedit[0]?.uname || "");
  const [emailid, setEmailid] = useState(loginedit[0]?.emailid || "");
  const [role, setRole] = useState(loginedit[0]?.role || "");
  const [passwrd, setPasswrd] = useState(loginedit[0]?.passwrd || "");
  const [phone, setPhone] = useState(loginedit[0]?.phone || "");
  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  let data = {
    logid: logid,
    uname: uname,
    emailid: emailid,
    role: role,
    passwrd: passwrd,
    refid: refid,
    phone:phone,
    isblock:0,
  };

  const Owner = [
    { label: "Administrator", id: "Administrator" },
    { label: "Committee Member", id: "Committee Member" },
  ];
  return (
    <div className="main-content">
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <main>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item md={12} xs={12} sm={12}>
            <h3 className="supcolor3">Add New Draw User</h3>
            <hr />
          </Grid>
          <Grid item md={3} xs={12} sm={12}>
            <TextField
              autoFocus
              size="small"
              id="outlined-case no-input"
              fullWidth
              value={uname}
              label="User Name"
              inputProps={{ maxLength: 30 }}
              onChange={async (e) => {
                // let retdt = await alphanumeric(e);
                setUname(e.target.value);
              }}
            />
          </Grid>
          <Grid item md={3} xs={12} sm={12}>
            <TextField
              size="small"
              id="outlined-case no-input"
              fullWidth
              label="Email Id"
              value={emailid}
              inputProps={{ maxLength: 100 }}
              onChange={async (e) => {
                setEmailid(e.target.value);
              }}
            />
          </Grid>
          <Grid item md={3} xs={12} sm={12}>
            <TextField
              size="small"
              id="outlined-case no-input"
              fullWidth
              label="Phone Number"
              value={phone}
              inputProps={{ maxLength: 12 }}
              onChange={async (e) => {
                let retdt = await onlynumeric(e);
                setPhone(retdt);
              }}
            />
          </Grid>
          <Grid item md={3} xs={12} sm={12}>
            <Autocomplete
              autoFocus
              disablePortal
              id="combo-box-demo"
              size="small"
              value={role}
              options={Owner}
              onChange={(e, newValue) => setRole(newValue.label)}
              renderInput={(params) => <TextField {...params} label="Role" />}
            />
          </Grid>

          <Grid item md={3} xs={12} sm={12}>
            <TextField
              size="small"
              type={showPassword ? 'text' : 'password'}
              id="outlined-case no-input"
              fullWidth
              label="Password"
              value={passwrd}
              inputProps={{ maxLength: 20 }}
              InputProps={{
                endAdornment: (<InputAdornment position="end" style={{cursor:"pointer"}} onClick={handleClickShowPassword}>
                 {showPassword ? <VisibilityOff /> : <Visibility />}
              </InputAdornment>),
              }}
                onChange={async (e) => {
                // let retdt = await alphanumeric(e);
                setPasswrd(e.target.value);
              }}
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <Button
              variant="contained"
              type="button"
              style={{ float: "right" }}
              onClick={() => {
                (async () => {
                  try {
                    let rettostmsg = "";
                    if (uname.length <= 0) {
                      rettostmsg = tostmsg({
                        msg: "Enter User Name",
                        code: 100,
                      });
                      setAlertdata(rettostmsg);
                      setAlertopen(rettostmsg.open);
                      return;
                    }
                    if (emailid.length <= 0) {
                      rettostmsg = tostmsg({
                        msg: "Enter Email Id",
                        code: 100,
                      });
                      setAlertdata(rettostmsg);
                      setAlertopen(rettostmsg.open);
                      return;
                    }
                    var emailregex=/\S+@\S+\.\S+/;
                    if (!emailregex.test(emailid)) {
                      rettostmsg = tostmsg({
                        msg: "Enter Valid Email Id",
                        code: 100,
                      });
                      setAlertdata(rettostmsg);
                      setAlertopen(rettostmsg.open);
                      return;
                    }

                    if (passwrd.length <= 0) {
                      rettostmsg = tostmsg({
                        msg: "Enter Password",
                        code: 100,
                      });
                      setAlertdata(rettostmsg);
                      setAlertopen(rettostmsg.open);
                      return;
                    }

                    // const emailduplicate = await GetLogin({ emailid: emailid });
                    // if (emailduplicate.code == 200) {
                    //   rettostmsg = tostmsg({
                    //     msg: "This Email ID already exist",
                    //     code: 100,
                    //   });
                    //   setAlertdata(rettostmsg);
                    //   setAlertopen(rettostmsg.open);
                    //   return;
                    // }
                    const user = await SaveLogin(data);
                    rettostmsg = tostmsg(user);
                    // alert(JSON.stringify(user));
                    setAlertdata(rettostmsg);
                    setAlertopen(rettostmsg.open);

                    if (user.code == 100) {
                    }

                    if (user.code == 200) {
                      setTimeout(() => {
                        LoginStore(setLoginedit, {
                          type: "clearloginedit",
                          data: {},
                        });
                        navigate("/adminuserlist");
                      });
                    }
                  } catch (e) {}
                })();
              }}
            >
              <DoneIcon /> Submit
            </Button>
            <Button
              variant="contained"
              type="button"
              className="primcolor1_bg"
              style={{
                float: "right",
                backgroundColor: "#f3797e",
                marginRight: "10px",
              }}
              onClick={() => {
                window.location.reload();
              }}
            >
              <RestartAltIcon /> Reset
            </Button>
            <Button
              variant="contained"
              type="button"
              className="primcolor1_bg"
              style={{
                float: "right",
                backgroundColor: "#555555",
                marginRight: "10px",
              }}
              onClick={()=>{navigate("/adminuserlist")}}
            >
             Back To Admin User List
            </Button>
          </Grid>
        </Grid>
      </main>
    </div>
  );
};

export default AdminUser;
