import React from "react";
import Footer from "../../Componants/Navbar/Footer";
import NavbarComp from "../../Componants/Navbar/NavbarComp";
import SidebarComp from "../../Componants/Sidebar/SidebarComp";
import VendorList from "../../Componants/Vendor/VendorList";
 
const VendorTblScreen = () => {
  return (
    <div>
      <SidebarComp />
      <NavbarComp />
     <VendorList/>
     <Footer/>
    </div>
  );
};

export default VendorTblScreen;
