import React from "react";
import Footer from "../../Componants/Navbar/Footer";
import NavbarComp from "../../Componants/Navbar/NavbarComp";
 import SidebarComp from "../../Componants/Sidebar/SidebarComp";
 import ArchiveList from "../../Componants/Shows/ArchiveList";

const ArchiveScreen = () => {
  return (
    <div>
    <SidebarComp />
    <NavbarComp />
   <ArchiveList/>
   <Footer/></div>
  )
}

export default ArchiveScreen