import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { GetEvent } from "../API/EventModel";
import { GetLogin } from "../API/LoginModel";

export default function OwnernameSuggest({
  setLogid,
  uname,
  setUname,
  lblname,
}) {
  const [rows, setRows] = useState([]);
  let tabledata = {};

  const getData = async () => {
    tabledata = await GetLogin({role:"Committee Member"});
    // alert(JSON.stringify(tabledata.data[0].clientname));
    const arr = [];

    // if (tabledata.length > 0)
    tabledata.data.forEach((element) => {
      //   alert(element.emailid);
      arr.push({
        label: `${element.uname}-${element.phone} <${element.emailid}>`,
        label1: element.emailid,
        id: element.logid,
      });
      setRows([...arr]);
    });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      fullWidth
      size="small"
      value={uname}
      options={rows}
      onChange={(event, newValue) => {
        setLogid(newValue.id);
        setUname(newValue.label);
      }}
      sx={{fontSize:"10px"}}
      //   onChange={(e, newValue) => setProfilevehical(newValue.label)}
      renderInput={(params) => <TextField {...params} label={lblname} sx={{fontSize:"10px"}} />}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
