import React from "react";
import Footer from "../../Componants/Navbar/Footer";
import NavbarComp from "../../Componants/Navbar/NavbarComp";
import ReportList from "../../Componants/Report/ReportList";
 import SidebarComp from "../../Componants/Sidebar/SidebarComp";
import "../../CSS/Base.css";

const ReportScreen = () => {
  return (
    <div>
      <SidebarComp />
      <NavbarComp />
      <ReportList/>
      <Footer/>
    </div>
  );
};

export default ReportScreen;
