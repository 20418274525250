import React from "react";
import EventList from "../../Componants/Event/EventList";
import Footer from "../../Componants/Navbar/Footer";
import NavbarComp from "../../Componants/Navbar/NavbarComp";
import SidebarComp from "../../Componants/Sidebar/SidebarComp";
   
const EventTblScreen = () => {
  return (
    <div>
      <SidebarComp />
      <NavbarComp />
     <EventList/>
     <Footer/>
    </div>
  );
};

export default EventTblScreen;
