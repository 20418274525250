import React, { createContext, useEffect, useState, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { Passwordstore } from "../../Reducer/ForgotPswReducer";
import { LogdataReducer } from "../../Reducers/LogindataReducer";
import { store } from "../Context/GlobalReducer";
import { eventstore } from "./EventReducer";
import { loginstore } from "./LoginReducer";
import { showstore } from "./ShowReducer";
import { vendorstore } from "./VendorReducer";
import { venustore } from "./VenuReducer";

export const UserContext = createContext({});

export const UserContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const [eventedit, setEventedit] = eventstore.useState("eventedit");
  const [vendoredit, setVendoredit] = vendorstore.useState("vendoredit");
  const [venuedit, setVenuedit] = venustore.useState("venuedit");
  const [showedit, setShowedit] = showstore.useState("showedit");
  const [loginedit, setLoginedit] = loginstore.useState("loginedit");
  const [pswinputdisplya,setPswinputdisply] = Passwordstore.useState("pswinputdisplya");
  const [logdata, setlogdata_dispatch] = useReducer(LogdataReducer,[]);
  const [formname, setFormname] = useState();
  const value = {
    navigate,
    formname, setFormname,
    eventedit,
    setEventedit,
    vendoredit,
    setVendoredit,
    venuedit,
    setVenuedit,
    showedit,
    setShowedit,
    loginedit,
    setLoginedit,
    pswinputdisplya,
    setPswinputdisply,
    logdata, setlogdata_dispatch,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
export default UserContextProvider;
