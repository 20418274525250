import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DoneIcon from "@mui/icons-material/Done";
import { SaveVenu } from "../../API/VenuModel";
import { useNavigate } from "react-router-dom";
import { alphanumeric, onlynumeric, tostmsg } from "../../MIS/Global";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import VendornameSuggest from "../../Textsuggestfield/VendornameSuggest";
import TextSuggestProvience from "../../Textsuggestfield/TextSuggestProvience";
import { UserContext } from "../Context/UserContext";
import { VenuStore } from "../Context/VenuReducer";

const Venues = () => {
  const { venuedit, setVenuedit,formname, setFormname  } = useContext(UserContext);
  const [venueid, setVenueid] = useState(venuedit[0]?.venueid);
  const [vendrid, setVendrid] = useState(venuedit[0]?.vendrid || "");
  const [vendrname, setVendrname] = useState(venuedit[0]?.vendrname || "");
  const [venuname, setVenuname] = useState(venuedit[0]?.venuname || "");
  const [descrp, setDescrp] = useState(venuedit[0]?.descrp || "");
  const [address, setAddress] = useState(venuedit[0]?.address || "");
  const [city, setCity] = useState(venuedit[0]?.city || "");
  const [pincode, setPincode] = useState(venuedit[0]?.pincode || "");
  const [country, setCountry] = useState(venuedit[0]?.country || "Canada");
  const [state, setState] = useState(venuedit[0]?.province || "");
  const [isstatus, setIsstatus] = useState(venuedit[0]?.isstatus || "Enabled");
  const [uid, setUid] = useState(venuedit[0]?.uid || 0);
  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);
  const navigate = useNavigate();

  let data = {
    venueid: venueid,
    vendrid: vendrid,
    venuname: venuname,
    descrp: descrp,
    address: address,
    city: city,
    pincode: pincode,
    country: country,
    province: state,
    isstatus: isstatus,
    uid: uid,
  };

  const Owner = [
    { label: "Wade", id: "Wade" },
    { label: "Dave", id: "Dave" },
  ];
  return (
    <div className="main-content">
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <main>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item md={12} xs={12} sm={12}>
            <h3 className="supcolor3">{formname||"Add New Draw Address"}</h3>
            <hr />
          </Grid>
          <Grid item md={4} xs={12} sm={12} sx={{display:"none"}}>
            <VendornameSuggest
               setVendrid={setVendrid}
              vendrname={vendrname}
              setVendrname={setVendrname}
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <TextField
              autoFocus
              size="small"
              id="outlined-case no-input"
              fullWidth
              value={venuname}
              label="Draw Address"
              inputProps={{ maxLength: 150 }}
              onChange={async (e) => {
                let retdt = await alphanumeric(e);
                setVenuname(retdt);
              }}
            />
          </Grid>

          <Grid item md={6} xs={12} sm={12}>
            <TextareaAutosize
              minRows={3}
              placeholder="Description"
              style={{ width: "100%",padding:"5px" }}
              value={descrp}
              onChange={async (e) => {
                // let retdt = await alphanumeric(e);
                setDescrp(e.target.value);
              }}
            />
          </Grid>
          <Grid item md={6} xs={12} sm={12}>
            <TextareaAutosize
              minRows={3}
              placeholder="Address Detail"
              style={{ width: "100%",padding:"5px" }}
              value={address}
              onChange={async (e) => {
                // let retdt = await alphanumeric(e);
                setAddress(e.target.value);
              }}
            />
          </Grid>
          <Grid item md={3} xs={12} sm={12}>
            <TextField
              size="small"
              id="outlined-case no-input"
              fullWidth
              label="City"
              value={city}
              inputProps={{ maxLength: 20 }}
              onChange={async (e) => {
                let retdt = await alphanumeric(e);
                setCity(retdt);
              }}
            />
          </Grid>
          <Grid item md={3} xs={12} sm={12}>
            <TextField
              size="small"
              id="outlined-case no-input"
              fullWidth
              value={pincode}
              label="Postal Code"
              inputProps={{ maxLength: 7 }}
              onChange={async (e) => {
                let retdt = await alphanumeric(e);
                setPincode(retdt);
              }}
            />
          </Grid>
          <Grid item md={3} xs={12} sm={12}>
            <TextField
              size="small"
              id="outlined-case no-input"
              fullWidth
              disabled
              value="Canada"
              label="Canada"
              inputProps={{ maxLength: 20 }}
              onChange={async (e) => {
                let retdt = await alphanumeric(e);
                setCountry(retdt);
              }}
            />
          </Grid>
          <Grid item md={3} xs={12} sm={12}>
            <TextSuggestProvience state={state} setState={setState} />
          </Grid>
          <Grid item md={3} xs={12} sm={12} sx={{ display: "none" }}>
            <Button variant="contained" component="label">
              Upload Map
              <input hidden accept="image/*" multiple type="file" />
            </Button>
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <Button
              variant="contained"
              type="button"
              style={{ float: "right" }}
              onClick={() => {
                (async () => {
                  try {
                    const venu = await SaveVenu(data);
                    let rettostmsg = tostmsg(venu);
                    // alert(JSON.stringify(venu));
                    setAlertdata(rettostmsg);
                    setAlertopen(rettostmsg.open);
                    if (venu.code == 100) {
                    }

                    if (venu.code == 200) {
                      setTimeout(() => {
                        VenuStore(setVenuedit, {
                          type: "clearvenuedit",
                          data: {},
                        });
                        navigate("/venueslist");
                      });
                    }
                  } catch (e) {}
                })();
              }}
            >
              <DoneIcon /> Submit
            </Button>
            <Button
              variant="contained"
              type="button"
              className="primcolor1_bg"
              style={{
                float: "right",
                backgroundColor: "#f3797e",
                marginRight: "10px",
              }}
              onClick={()=>{window.location.reload()}}
            >
              <RestartAltIcon /> Reset
            </Button>
            <Button
              variant="contained"
              type="button"
              className="primcolor1_bg"
              style={{
                float: "right",
                backgroundColor: "#555555",
                marginRight: "10px",
              }}
              onClick={()=>{navigate("/venueslist")}}
            >
             Back To Draw Address List
            </Button>
          </Grid>
        </Grid>
      </main>
    </div>
  );
};

export default Venues;
