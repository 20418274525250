import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { render } from "react-dom";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import VendorScreen from "./Screen/VendorScreen/VendorScreen";
import VendorTblScreen from "./Screen/VendorScreen/VendorTblScreen";
import VenuesTblScreen from "./Screen/VenuesScreen/VenuesTblScreen";
import VenuesScreen from "./Screen/VenuesScreen/VenuesScreen";
import EventTblScreen from "./Screen/EventScreen/EventTblScreen";
import EventScreen from "./Screen/EventScreen/EventScreen";
import ShowScreen from "./Screen/ShowScreen/ShowScreen";
import ShowTblScreen from "./Screen/ShowScreen/ShowTblScreen";
import ShowDetailTab from "./Componants/Shows/ShowDetailTab";
import ReportScreen from "./Screen/ReportScreen/ReportScreen";
import AdminUserScreen from "./Screen/AdminUserScreen/AdminUserScreen";
import AdminUserTblScreen from "./Screen/AdminUserScreen/AdminTblScreen";
import HomeScreen from "./Screen/HomeScreen/HomeScreen";
import EnquiryTblScreen from "./Screen/Enquiry/EnquiryTblScreen";
import UserContextProvider from "./Componants/Context/UserContext";
import ForgotPassCom from "./Componants/ForgotPass/ForgotPassCom";
import UserReportScreen from "./Screen/ReportScreen/UserReportScreen";
import ArchiveScreen from "./Screen/ArchiveScreen/ArchiveScreen";

const rootElement = document.getElementById("root");

render(
  <BrowserRouter>
    <UserContextProvider>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/home" element={<HomeScreen />} />

        <Route path="/vendor" element={<VendorScreen />} />
        <Route path="/vendorlist" element={<VendorTblScreen />} />
        <Route path="/venues" element={<VenuesScreen />} />
        <Route path="/venueslist" element={<VenuesTblScreen />} />
        <Route path="/event" element={<EventScreen />} />
        <Route path="/eventlist" element={<EventTblScreen />} />
        <Route path="/shows" element={<ShowScreen />} />
        <Route path="/showdetail" element={<ShowDetailTab />} />
        <Route path="/showlist" element={<ShowTblScreen />} />
        <Route path="/archivelist" element={<ArchiveScreen/>} />      
        <Route path="/report" element={<ReportScreen />} />
        <Route path="/userreport" element={<UserReportScreen/>} />
        <Route path="/adminuser" element={<AdminUserScreen />} />
        <Route path="/adminuserlist" element={<AdminUserTblScreen />} />
        <Route path="/enquirylist" element={<EnquiryTblScreen />} />
        <Route path="/forgotpass" element={<ForgotPassCom />} />
      </Routes>
    </UserContextProvider>
  </BrowserRouter>,
  rootElement
);
