import React from "react";
import Footer from "../../Componants/Navbar/Footer";
import NavbarComp from "../../Componants/Navbar/NavbarComp";
import UserReportList from "../../Componants/Report/UserReportList";

 import SidebarComp from "../../Componants/Sidebar/SidebarComp";
import "../../CSS/Base.css";

const UserReportScreen = () => {
  return (
    <div>
      <SidebarComp />
      <NavbarComp />
      <UserReportList/>
      <Footer/>
      
    </div>
  );
};

export default UserReportScreen;
