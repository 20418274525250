import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import ListIcon from "@mui/icons-material/List";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { GetEnquiry } from "../API/EnquiryModel";
import { GetUserLogin } from "../API/UserLoginModel";
const columns = [
  { label: "User Name", minWidth: "40%" },
  { label: "Email", minWidth: "40%" },  
  { label: "", minWidth: "20%" },
];

function createData(logid,uname,emailid,search) {
  return {
    logid,uname,emailid,search
  };
}

export default function UserReportTbl(props) {
  const [rows, setRows] = useState([{ id: "1" }]);
  const [srows, setSrows] = useState([{ id: "1" }]);
  const [searched, setSearched] = useState("");
  let tabledata = {};
  const getData = async () => {
    setRows([]);
    setSrows([]);
    // let data = { fdate: monday, tdate: saturday };
    tabledata = await GetUserLogin();
    // alert(JSON.stringify(tabledata.data));
    const arr = [];
    tabledata.data.forEach((element) => {
      let search = element.uname+"-"+element.emailid+"-";
      arr.push(
        createData(
         element.logid,element.uname,element.emailid,search
          
        )
      );
      setRows([...arr]);
      setSrows([...arr]);
    });
  };

  useEffect(() => {
    getData();
  }, [props.searchdata]);
  const requestSearch = (searchedVal, srows) => {
    const filteredRows = srows.filter((row) => {
      return row.search.toLowerCase().includes(searchedVal.toLowerCase());
    });
    return filteredRows;
  };

  const search = (searchedVal) => {
    let filteredRows = requestSearch(searchedVal, srows);
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className="">
      <Paper sx={{ width: "100%" }}>
        <TextField
          placeholder="search"
          fullWidth
          className="searchbar"
          onChange={(searchVal) => search(searchVal.target.value)}
        />

        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ fontSize: "100pt!important" }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ width: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  let month = new Date(row.edate).getMonth() + 1;
                  if (month.toString().length <= 1) {
                    month = `0${month}`;
                  }
                  let day = new Date(row.edate).getDate();
                  if (day.toString().length <= 1) {
                    day = `0${day}`;
                  }                                    
                  let year = new Date(row.edate).getFullYear();                  
                  let edate =`${day}/${month}/${year}`;
                  return (
                    <TableRow style={{ cursor: "pointer" }} hover tabIndex={-1}>
                      <TableCell>{row.uname}</TableCell>
                      <TableCell>
                      {row.emailid}
                      </TableCell>                      
                      <TableCell style={{color:"#7978e9"}}>
                         {/* <DeleteForeverIcon style={{color:"red"}}/> */}
                         <a href="#">View</a>
                      </TableCell>
                      {/* AddIcon  
                        ListIcon  
                        PictureAsPdfIcon  
                        SavedSearchIcon   */}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[100, 250, 500, 1000]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
