import { Grid } from "@mui/material";
import React from "react";
import ArchiveDrawTbl from "../../Table/ArchiveDrawTbl";
 

const ArchiveList = () => {
 
  return (
    <div className="main-content">
      <main>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item md={12} xs={12} sm={12}>
            <h3 className="supcolor3">Archive Draw List</h3>
            <hr />
          </Grid>
     
          <Grid item md={12} xs={12} sm={12}>
           <ArchiveDrawTbl/>
          </Grid>            
        </Grid>
      </main>
      
    </div>
  );
};

export default ArchiveList;
