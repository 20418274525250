import { BASE_URL } from "../MIS/Global";
import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import Loader from "../Componants/loader/Loader";

export const Saveorder = async (data) => {
 
  ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));
  let url = "saveorder";
  if (data.orderid > 0) {
    url = "updateorder";
  }
  let ret = {};
  var config = {
    method: "post",
    url: BASE_URL + url,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  await axios(config)
    .then(function (response) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = response.data;
      if (ret.code == 200) {
        ret = { msg: ret.msg, code: 200, data: ret.data };
        return ret;
      } else if (ret.code == 400) {
        ret = { msg: ret.msg, code: 400 };
        return ret;
      } else {
        ret = { msg: "something went wrong! please try again ", code: 500 };
        return ret;
      }
    })
    .catch(function (error) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = { msg: "something went wrong! please try again ", code: 500 };
      return ret;
    });
  return ret;
};


export const Getorder = async (data) => {
    let ret = {};
    var config = {
      method: "post",
      url: BASE_URL + "getorder",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));
    await axios(config)
      .then(function (response) {
        ReactDOM.render(
          <Loader bal={false}></Loader>,
          document.getElementById("root1")
        );
        ret = response.data;
        // alert(JSON.stringify(ret))
        if (ret.code == 200) {
          ret = { msg: ret.msg, code: 200, data: ret.data };
          return ret;
        }
        else if (ret.code == 300) {
          ret = { msg: ret.msg, code: 300,data: ret.data };
          return ret;
        } 
        else if (ret.code == 400) {
          ret = { msg: ret.msg, code: 400 };
          return ret;
        } else {
          ret = { msg: "something went wrong! please try again ", code: 500 };
          return ret;
        }
      })
      .catch(function (error) {
        ReactDOM.render(
          <Loader bal={false}></Loader>,
          document.getElementById("root1")
        );
        ret = { msg: "something went wrong! please try again ", code: 500 };
        return ret;
      });
    return ret;
  };