import { BASE_URL } from "../MIS/Global";
import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import Loader from "../Componants/loader/Loader";
import { jsx } from "@emotion/react";

export const SaveLogin = async (data) => {
  //   alert(JSON.stringify(data));
  
  ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));

  let url = "savelogin";
  if (data.logid > 0) {
    url = "updatelogin";
  }
  let ret = {};
  var config = {
    method: "post",
    url: BASE_URL + url,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  await axios(config)
    .then(function (response) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = response.data;
        // alert(JSON.stringify(ret))
      if (ret.code == 200) {
        ret = { msg: ret.msg, code: 200, data: ret.data };
        return ret;
      } else if (ret.code == 400) {
        ret = { msg: ret.msg, code: 400 };
        return ret;
      } 
      else if (ret.code == 100) {
        ret = { msg: ret.msg, code: 100 };
        return ret;
      }else {
        ret = { msg: "something went wrong! please try again1 ", code: 500 };

        return ret;
      }
    })
    .catch(function (error) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = { msg: error, code: 500 };

      return ret;
    });

  return ret;
};

export const GetLogin = async (data) => {
  let ret = {};
  var config = {
    method: "post",
    url: BASE_URL + "getlogin",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));

  await axios(config)
    .then(function (response) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = response.data;
      // alert(JSON.stringify(ret))
      if (ret.code == 200) {
        ret = { msg: ret.msg, code: 200, data: ret.data };
        return ret;
      } else if (ret.code == 300) {
        ret = { msg: "Invalid Email/Password", code: 100 };
        return ret;
      } else if (ret.code == 400) {
        ret = { msg: ret.msg, code: 400 };
        return ret;
      } else {
        ret = { msg: "something went wrong! please try again ", code: 500 };
        return ret;
      }
    })
    .catch(function (error) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = { msg: "something went wrong! please try again ", code: 500 };
      return ret;
    });
  return ret;
};



export const DeleteLogin = async (data) => {
  let ret = {};
  var config = {
    method: "post",
    url: BASE_URL + "deletelogin",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));

  await axios(config)
    .then(function (response) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = response.data;
      if (ret.code == 200) {
        ret = { msg: ret.msg, code: 200, data: ret.data };
        return ret;
      } else if (ret.code == 400) {
        ret = { msg: ret.msg, code: 400 };
        return ret;
      } else {
        
        if(ret?.err?.errno==1451){
          
          ret = { msg: "This user exist in draw committee", code: 500 };
          return ret;
        }
        ret = { msg: "something went wrong! please try again ", code: 500 };
        return ret;
      }
    })
    .catch(function (error) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = { msg: "something went wrong! please try again", code: 500 };
      return ret;
    });
  return ret;
};



export const updatepassword = async (data) => {
 
  let ret = {};
  var config = {
    method: "post",
    url: BASE_URL + "pswupdate",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));

  await axios(config)
    .then(function (response) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = response.data;
      // alert(JSON.stringify(ret))
      if (ret.code == 200) {
        ret = { msg: ret.msg, code: 200, data: ret.data };
        return ret;
      } else if (ret.code == 100) {
        ret = { msg: ret.msg, code: 100 };
        return ret;
      } else if (ret.code == 400) {
        ret = { msg: ret.msg, code: 400 };
        return ret;
      } else {
        ret = { msg: "something went wrong! please try again ", code: 500 };
        return ret;
      }
    })
    .catch(function (error) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = { msg: "something went wrong! please try again ", code: 500 };
      return ret;
    });
  return ret;
};

export const Sendotp_email = async (data) => {
  // if (data.email.length <= 0) {
  //   return { msg: "Enter valid email id", code: 100 };
  // }
   let ret = {};
  var config = {
    method: "post",
    url: BASE_URL + "sendotp",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  ReactDOM.render(<Loader></Loader>, document.getElementById("root1"));

  await axios(config)
    .then(function (response) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = response.data;
      if (ret.code == 200) {
        ret = { msg: ret.msg, code: 200, data: ret.data };
        return ret;
      } else if (ret.code == 100) {
        ret = { msg: ret.msg, code: 100 };
        return ret;
      } else if (ret.code == 400) {
        ret = { msg: ret.msg, code: 400 };
        return ret;
      } else {
        ret = { msg: "something went wrong! please try again ", code: 500 };
        return ret;
      }
    })
    .catch(function (error) {
      ReactDOM.render(
        <Loader bal={false}></Loader>,
        document.getElementById("root1")
      );
      ret = { msg: "something went wrong! please try again ", code: 500 };
      return ret;
    });
  return ret;
};

