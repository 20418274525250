import React, { useContext, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Autocomplete, Button, TextField } from "@mui/material";
import { Link, Navigate, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import ListIcon from "@mui/icons-material/List";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { TabPanel } from "@material-ui/lab";
import FullWidthTabs from "../Screen/ShowScreen/FullWidthTabs";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DeleteShow, GetShow, UpdateShowStatus } from "../API/ShowModel";
import { UserContext } from "../Componants/Context/UserContext";
import { ShowStore, showstore } from "../Componants/Context/ShowReducer";
import { formatISOToCustom, tostmsg } from "../MIS/Global";
import AlertMessageComp from "../Componants/AlertMessageCom/AlertMessageComp";
 import Typography from '@mui/material/Typography';
import ReportTbl from "./ReportTbl";
import Tooltip from '@mui/material/Tooltip';
import excelIcon from "../../src/Assets/excel_icon.png"

 const columns = [
  { label: "Draw Id", minWidth: "8%" },
  { label: "Draw Name", minWidth: "15%" },
  { label: "Draw Date & Time", minWidth: "12%" },
  // { label: "Event", minWidth: "15%" },
  { label: "Committee Name", minWidth: "15%" },
  { label: "Draw Address", minWidth: "15%" },
  { label: "Total Sale Count", minWidth: "10%" },
  { label: "Status", minWidth: "17%" },
  { label: "Action", minWidth: "15%" },

];
const Owner = [
  { label: "Draft", id: "Draft" },
  { label: "Posted", id: "Posted" },
  { label: "Archive", id: "Archive" }, 
];

function createData(showid, showdetail, isstatus,show_time,img,ticketdetail,totsalecnt, search) {
  return {
    showid,
    showdetail,
    isstatus,
    show_time,
    img,ticketdetail,
    totsalecnt,
    search,

  };
}
const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: window.innerWidth-300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  paddingTop:5,
  "@media (max-width: 600px)":{
    width:"80%"
  }
};


export default function ShowTbl(props) {
  const [rows, setRows] = useState([]);
  const [srows, setSrows] = useState([]);
  const [owner, setOwner] = useState("");
  const [searched, setSearched] = useState("");
  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const navigate = useNavigate();

  const { showedit, setShowedit,formname, setFormname } = useContext(UserContext);
  const UpdateStatus = async (id, isstatus) => {
    let show = await UpdateShowStatus({ showid: id, isstatus: isstatus });
    return show;
  };
  let tabledata = {};

  const getEditdata = async (id) => {
    tabledata = await GetShow({ showid: id});
    // alert(JSON.stringify(tabledata));
    let x = ShowStore(setShowedit, {
      type: "showedit",
      data: tabledata.data,
    });
    return x;
  };

  const requestSearch = (searchedVal, srows) => {
    const filteredRows = srows.filter((row) => {
      return row.search.toLowerCase().includes(searchedVal.toLowerCase());
    });
    return filteredRows;
  };

  const getData = async () => {
    setRows([]);
    setSrows([]);
    // let data = { fdate: monday, tdate: saturday };
    tabledata = await GetShow({isstatus:"!Archive" });
    
    // alert(JSON.stringify(tabledata.data));
    const arr = [];
    const sortarr = [];
    tabledata?.data.forEach((element) => {
      let newVal={
        showid:element.showid,
        showdetail:element.showdetail,
        isstatus:element.isstatus,
        show_time:element.show_time,
        img:element.drawimg,
        ticketdetail:element.ticketdet,
        totsalecnt:element?.totsalecnt,
        search:"",
        showname:element.showdetail[0]?.showname

      }
      sortarr.push(newVal);
    });
  
  
  // sortarr.sort(GetSortOrder("showname"));
  sortarr.sort((a, b) => b?.showid - a?.showid);
  // alert(JSON.stringify(sortarr))
  sortarr.forEach((element) => {
      let search = element.showdetail[0]?.showname+"-"+element.showdetail[0]?.eventname+"-"+element.showdetail[0]?.vendrname+"-"+element.showdetail[0]?.venuname;
      arr.push(
        createData(
          element.showid,
          element.showdetail,
          element.isstatus,
          element.show_time,
          element.img,
          element.ticketdetail,
          element?.totsalecnt,
          search
        )
      );
      setRows([...arr]);
      setSrows([...arr]);
    });
  };

  function GetSortOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}    
  useEffect(() => {
    getData();
  }, [props.searchdata]);

  const search = (searchedVal) => {
    let filteredRows = requestSearch(searchedVal, srows);
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [value, setValue] = React.useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    maxHeight: "620px",
    transform: "translate(-50%, -50%)",
    // width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    // overflowY: "scroll",
    padding: 4,
  };

  const [showid, setShowid] = useState(0);
  const formattedDateTime = (dateTime) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const date = new Date(dateTime).toLocaleDateString('en-GB', options);
    
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    const time = new Date(dateTime).toLocaleTimeString('en-US', timeOptions);
    
    return `${date} ${time}`;
  };
  return (
    <div className="">
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <Paper sx={{ width: "100%" }}>
        <TextField
          placeholder="search"
          fullWidth
          className="searchbar"
          onChange={(searchVal) => search(searchVal.target.value)}
        />

        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ fontSize: "100pt!important" }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ width: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  // alert(row.showdetail[0]?.showname);
                    //alert(row.show_time)
                  let month = new Date(row.show_time).getMonth() + 1;
                  if (month.toString().length <= 1) {
                    month = `0${month}`;
                  }
                  let day = new Date(row.show_time).getDate();
                  if (day.toString().length <= 1) {
                    day = `0${day}`;
                  }
                  let hours = new Date(row.show_time).getHours();
                  // if (day.toString().length <= 1) {
                  //   day = `0${day}`;
                  // }
                  let minutes= new Date(row.show_time).getMinutes();
                  let year = new Date(row.show_time).getFullYear();
                  // year = Number(year) - Number(e.target.value);
                  let edate=`${day}-${month}-${year} ${hours}:${minutes}`;
                  return (
                    <TableRow style={{ cursor: "pointer"}} hover tabIndex={-1}>
                      <TableCell>#{row.showid}</TableCell>
                      <TableCell>{row.showdetail[0]?.showname}</TableCell>
                      <TableCell>{formattedDateTime(formatISOToCustom(row?.show_time))}</TableCell>
                      {/* <TableCell>{row.showdetail[0]?.eventname}</TableCell> */}
                      <TableCell>{row.showdetail[0]?.vendrname}</TableCell>
                      <TableCell>{row.showdetail[0]?.venuname}</TableCell>
                      <TableCell align="center">{row.totsalecnt?row.totsalecnt:0}</TableCell>
                      <TableCell>
                        <Autocomplete
                          disablePortal
                          disableClearable
                          style={{ backgroundColor: "tomato" }}
                          id="combo-box-demo"
                          size="small"
                          value={row.isstatus}
                          options={Owner}
                          onChange={(e, newValue) => {
                            (async () => {
                              try {
                                // setOwner(newValue.label);
                                 if(newValue?.label=="Archive"){
                                  let vendor = await UpdateStatus(
                                  row.showid,
                                  newValue.label
                                );
                                   let rettostmsg = tostmsg(vendor);
                                // alert(JSON.stringify(venu));
                                setAlertdata(rettostmsg);
                                setAlertopen(rettostmsg.open);
                                getData();
                                return;
                                }
                                let imgValid=row.img == undefined || row.img == null || row.img.toString() == "-";
                                let ticketValid=row.ticketdetail == undefined || row.ticketdetail == null || row.ticketdetail == "-" || row.ticketdetail.length <=0;
                                if (imgValid && ticketValid) {
                                  let rettostmsg = tostmsg({code:700,msg:"Please Upload Image \n Please Add Tickets"});                                
                                  setAlertdata(rettostmsg);
                                  setAlertopen(rettostmsg.open);                                  
                                  return e.preventDefault();
                                }                                
                                else if ( imgValid) {
                                  let rettostmsg = tostmsg({code:100,msg:"Please Upload Image"});
                                  // alert(JSON.stringify(venu));
                                  setAlertdata(rettostmsg);
                                  setAlertopen(rettostmsg.open);
                                  return e.preventDefault();
                                } else if ( ticketValid) {
                                  let rettostmsg = tostmsg({code:100,msg:"Please Add Tickets"});
                                  // alert(JSON.stringify(venu));
                                  setAlertdata(rettostmsg);
                                  setAlertopen(rettostmsg.open);
                                  return e.preventDefault();
                                }


                                // alert(JSON.stringify(row))
                                let vendor = await UpdateStatus(
                                  row.showid,
                                  newValue.label
                                );
                                let rettostmsg = tostmsg(vendor);
                                // alert(JSON.stringify(venu));
                                setAlertdata(rettostmsg);
                                setAlertopen(rettostmsg.open);
                                getData();
                              } catch (e) {}
                            })();
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </TableCell>
                      <TableCell style={{ color: "#7978e9",display:"flex", gap:"10px"}}>
                   
                      <Tooltip title="Configure" placement="top">
                        <SavedSearchIcon
                         sx={{display: row.isstatus== "Posted" ? "none"  : "" ,fontSize:"25px"}}
                          onClick={() => {
                            (async () => {
                              try {
                                let ret = await getEditdata(row.showid);
                                setTimeout(() => {
                                  handleOpen();
                                }, 1000);
                              } catch (e) {}
                            })();
                          }}
                        ></SavedSearchIcon>
                        </Tooltip>
                        <Tooltip title="Edit" placement="top">
                        <EditIcon
                          style={{fontSize:"25px"}}
                          onClick={() => {
                            (async () => {
                              try {
                                setFormname("Edit Draw");
                                let ret = await getEditdata(row.showid);
                                // alert(row.showid);
                                setTimeout(() => {
                                  navigate("/shows");
                                }, 1000);
                              } catch (e) {}
                            })();
                          }}
                        ></EditIcon>
                        </Tooltip>
                        <Tooltip title="Delete" placement="top">
                        <DeleteForeverIcon
                          style={{ fontSize:"25px",color: "red",display: row.isstatus== "Posted" ? "none"  : "" }}                          
                          onClick={() => {
                            (async () => {
                              try {
                                // alert(row.bnrid);
                                if (
                                  window.confirm(
                                    "Are You Sure....delete this record"
                                  ) == false
                                ) {
                                  return;
                                }
                                const bannerdelete = await DeleteShow({
                                  showid: row.showid,
                                  oldpath:row.img
                                });
                                let rettostmsg = tostmsg(bannerdelete);
                                setAlertdata(rettostmsg);
                                setAlertopen(rettostmsg.open);
                                if (bannerdelete.code == 100) {
                                  // getData();
                                  window.location.reload();
                                }
                                if (bannerdelete.code == 200) {
                                  // alert(brand.msg);
                                  setTimeout(() => {
                                    // getData();
                                    window.location.reload();
                                  });
                                } else {
                                  // alert(categorydelete.msg);
                                }
                              } catch (e) {}
                            })();
                          }}
                        />
                        </Tooltip>
                        <Tooltip title="Report" placement="top">
                          <img src={excelIcon}
                          style={{width:"25px",height:"25px"}}
                          onClick={()=>{setShowid(row.showid);handleOpen1();}}/> 
                         
                        </Tooltip>
                      </TableCell>
                    
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[100, 250, 500, 1000]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style}>
            <FullWidthTabs handleClose={handleClose} />
          </Box>
        </>
      </Modal>
      <Modal
        open={open1}
        onClose={handleClose1}
      >
        <Box sx={style1}>
           <div style={{display:"flex",justifyContent:"end",padding:"10px"}}>
                <Button sx={{backgroundColor:"#FF0000"}} variant="contained" type="button" onClick={handleClose1}>CLOSE</Button>
            </div>     
           <ReportTbl showid={showid}/>
        </Box>
      </Modal>
    </div>
  );
}
