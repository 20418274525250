import React from "react";
import AdminUserList from "../../Componants/AdminUser/AdminUserList";
 import Footer from "../../Componants/Navbar/Footer";
import NavbarComp from "../../Componants/Navbar/NavbarComp";
import SidebarComp from "../../Componants/Sidebar/SidebarComp";
   
const AdminUserTblScreen = () => {
  return (
    <div>
      <SidebarComp />
      <NavbarComp />
     <AdminUserList/>
     <Footer/>
    </div>
  );
};

export default AdminUserTblScreen;
