import React, { useContext, useEffect, useRef, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";

import AlertMessageComp from "../Componants/AlertMessageCom/AlertMessageComp";
import { Getorder } from "../API/ReportModel";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Link } from "react-router-dom";
import VendornameSuggest from "../Textsuggestfield/VendornameSuggest";
import VenunameSuggest from "../Textsuggestfield/VenunameSuggest";
import ShownameSuggest from "../Textsuggestfield/ShownameSuggest";
import UsernameSuggest from "../Textsuggestfield/UsernameSuggest";
import SearchIcon from "@mui/icons-material/Search";
import { formatISOToCustom } from "../MIS/Global";

const columns = [
  { label: "Order", minWidth: "20%" },
  { label: "Order Date", minWidth: "20%" },
  { label: "Draw Id", minWidth: "20%" },
  { label: "Draw Name", minWidth: "20%" },
  { label: "Draw Date & Time", minWidth: "20%" },
  { label: "Committee Name", minWidth: "20%" },
  { label: "Draw Address", minWidth: "20%" },
  { label: "Price Tier", minWidth: "20%" },
  { label: "Ticket Quantity", minWidth: "20%" },
  { label: "Transaction Total", minWidth: "20%" },
  { label: "Patron Name", minWidth: "20%" },
  { label: "Patron Address", minWidth: "20%" },
  { label: "Patron Email", minWidth: "20%" },
  { label: "Patron Phone", minWidth: "20%" },
  { label: "Pay Card No.", minWidth: "20%" },
  { label: "IP", minWidth: "20%" },
  { label: "Country", minWidth: "20%" },

];

function createData(
  orderno,
  edate,
  drawname,
  drawaddress,
  price,
  qty,
  total,
  uname,
  uaddress,
  uemail,
  uphone,
  card,
  ip,
  country,
  showid,
  vendernm,
  showtime,
  orderid,
  search
) {
  return {
    orderno,
    edate,
    drawname,
    drawaddress,
    price,
    qty,
    total,
    uname,
    uaddress,
    uemail,
    uphone,
    card,
    ip,
    country,
    showid,
    vendernm,
    showtime,
    orderid,
    search,
  };
}

export default function OrderReportTbl(props) {
  const [rows, setRows] = useState([{ id: "1" }]);
  const [srows, setSrows] = useState([{ id: "1" }]);
  const [searched, setSearched] = useState("");

  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);

  const [vendrid, setVendrid] = useState();
  const [vendrname, setVendrname] = useState();

  const [venuname, setVenuname] = useState();
  const [venueid, setVenueid] = useState();
  const [showname, setShowname] = useState();
  const [username, setUsername] = useState();
  const [uid, setUid] = useState();

  const [fdate, setFdate] = useState();
  const [tdate, setTdate] = useState();

  //   const { navigate, eventedit, setEventedit } = useContext(UserContext);

  let tabledata = {};

  const getData = async () => {
    setRows([]);
    setSrows([]);
    // let data = { fdate: monday, tdate: saturday };
    let data = {};
    let customwhr = "";
    if (!vendrname == "") {
      customwhr += ` and vendernm like '%${vendrname}%'`;
    }
    if (!venuname == "") {
      customwhr += ` and venuname like '%${venuname}%'`;
    }
    if (!showname == "") {
      customwhr += ` and shownm like '%${showname}%'`;
    }
    if (!username == "") {
      customwhr += ` and uid like '%${uid}%'`;
    }
    let fromdate = showdate(fdate, 1);
    let todate = showdate(tdate, 1);
    if (!fdate == "" && !tdate == "") {
      customwhr += ` and edate between '${fromdate}' and '${todate}'`;
    }

    customwhr += " order by edate desc";
    // alert(customwhr)

    tabledata = await Getorder({ customwhr: customwhr });

    // alert(JSON.stringify(tabledata));
    const arr = [];
    tabledata.data.forEach((element) => {
      let search =
        element.fname +
        "-" +
        element.lname +
        "-" +
        element.orderno +
        "-" +
        element.shownm +
        "-" +
        element.venuname +
        "-" +
        element.phno +
        "-" +
        element.email +
        "-"+ element?.cardno +"-"+element?.vendernm;
      arr.push(
        createData(
          element.orderno,
          element.edate,
          element.shownm,
          element.venuname,
          element.price,
          element.qty,
          element.payment,
          `${element.fname} ${element.lname}`,
          element.address,
          element.email,
          element.phno,
          element.cardno,
          element.ip,
          element.autocountry,
          element.showid,
          element?.vendernm,
          element?.showtime,
          element?.orderid,
          search
        )
      );
      
    });
     const sortedData = arr.sort((a, b) => b.orderid - a.orderid);
      setRows(sortedData);
      setSrows(sortedData);
  };

  // useEffect(() => {
  //   getData();
  // }, [props.searchdata]);

  const requestSearch = (searchedVal, srows) => {
    const filteredRows = srows.filter((row) => {
      return row.search.toLowerCase().includes(searchedVal.toLowerCase());
    });
    return filteredRows;
  };

  const search = (searchedVal) => {
    let filteredRows = requestSearch(searchedVal, srows);
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const showdate = (showDate, istype) => {
    let month = new Date(showDate).getMonth() + 1;
    if (month.toString().length <= 1) {
      month = `0${month}`;
    }
    let day = new Date(showDate).getDate();
    if (day.toString().length <= 1) {
      day = `0${day}`;
    }
    let hours = new Date(showDate).getHours();
    // if (day.toString().length <= 1) {
    //   day = `0${day}`;
    // }
    let minutes = new Date(showDate).getMinutes();
    let year = new Date(showDate).getFullYear();
    // year = Number(year) - Number(e.target.value)
    // return `${day}/${month}/${year} ${hours}:${minutes}`;
    if (istype == 0) {
      return `${day}/${month}/${year}`;
    } else {
      return `${year}/${month}/${day}`;
    }
  };
  const formattedDateTime = (dateTime) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const date = new Date(dateTime).toLocaleDateString('en-GB', options);
    
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    const time = new Date(dateTime).toLocaleTimeString('en-US', timeOptions);
    
    return `${date} ${time}`;
  };
  return (
    <div className="">
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item md={4} xs={12} sm={12}>
          <VendornameSuggest
            focusval={true}
            setVendrid={setVendrid}
            vendrname={vendrname}
            setVendrname={setVendrname}
          />
        </Grid>
        <Grid item md={5} xs={12} sm={12} sx={{display:"none"}}>
          <VenunameSuggest
            setVenueid={setVenueid}
            venuname={venuname}
            setVenuname={setVenuname}
          />
        </Grid>

        <Grid item md={5} xs={12} sm={12}>
          <ShownameSuggest showname={showname} setShowname={setShowname} />
        </Grid>
        <Grid item md={3} xs={12} sm={12}>
          <UsernameSuggest
            setUid={setUid}
            username={username}
            setUsername={setUsername}
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <TextField
            id="date"
            fullWidth
            size="small"
            label="From Date"
            // type="datetime-local"
            type="date"
            // value={showtime}
            // defaultValue="2017-05-24T10:30"
            sx={{ width: "100%" }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              setFdate(e.target.value);
            }}
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <TextField
            id="date"
            fullWidth
            size="small"
            label="To Date"
            // type="datetime-local"
            type="date"
            // value={showtime}
            // defaultValue="2017-05-24T10:30"
            sx={{ width: "100%" }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              setTdate(e.target.value);
            }}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Button
            variant="contained"
            type="button"
            className="primcolor1_bg"
            style={{
              float: "right",
              backgroundColor: "#f3797e",
              marginRight: "10px",
            }}
            onClick={() => {
              getData();
            }}
          >
            <SearchIcon /> Search
          </Button>
        </Grid>
      </Grid>
      <div style={{ display: "flex", justifyContent: "end", padding: "10px" }}>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls"
          filename="Detail Report"
          sheet="tablexls"
          buttonText="Export"
        />
      </div>

      <Paper sx={{ width: "100%" }}>
        <TextField
          placeholder="search"
          fullWidth
          className="searchbar"
          onChange={(searchVal) => search(searchVal.target.value)}
        />

        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ fontSize: "100pt!important" }}
            id="table-to-xls"
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ width: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  
                  let edate1 = row.edate!=undefined?showdate(row.edate, 0):"";
                   return (
                    <>
                      <TableRow
                        style={{ cursor: "pointer" }}
                        hover
                        tabIndex={-1}
                      >
                        <TableCell>#{row.orderno}</TableCell>
                        <TableCell>{edate1}</TableCell>
                        <TableCell>#{row.showid}</TableCell>
                        <TableCell>{row.drawname}</TableCell>
                        <TableCell>{row?.showtime?formattedDateTime(formatISOToCustom(row?.showtime)):''}</TableCell>
                        <TableCell>{row.vendernm}</TableCell>
                        <TableCell>{row.drawaddress}</TableCell>
                        <TableCell>{row.price}</TableCell>
                        <TableCell>{row.qty}</TableCell>
                        <TableCell>{row.total}</TableCell>
                        <TableCell>{row.uname}</TableCell>
                        <TableCell>{row.uaddress}</TableCell>
                        <TableCell>{row.uemail}</TableCell>
                        <TableCell>{row.uphone}</TableCell>
                        <TableCell>{row.card?row.card.length>=4?row.card.slice(-4):"NA":""}</TableCell>
                        <TableCell>{row.ip}</TableCell>
                        <TableCell>{row.country}</TableCell>

                      </TableRow>
                    </>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={{ display: "none" }}
          rowsPerPageOptions={[1000, 5000, 10000]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
