import React from "react";
import EnquiryList from "../../Componants/Enquiry/EnquiryList";
 import Footer from "../../Componants/Navbar/Footer";
import NavbarComp from "../../Componants/Navbar/NavbarComp";
import SidebarComp from "../../Componants/Sidebar/SidebarComp";
   
const EnquiryTblScreen = () => {
  return (
    <div>
      <SidebarComp />
      <NavbarComp />
     <EnquiryList/>
     <Footer/>
    </div>
  );
};

export default EnquiryTblScreen;
