import { createStore } from "state-pool";

export const loginstore = createStore();
loginstore.setState("loginedit", {});

export const LoginStore = (loginedit, data) => {
  // alert(JSON.stringify(data));
  switch (data.type) {
    case "loginedit":
      loginedit(data.data);
      //   eventedit.eventid = "1";
      return 1;
    case "clearloginedit":
      loginedit(data.data);
      //   eventedit.eventid = "1";
      break;
    default:
      break;
  }
};
