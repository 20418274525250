import React, { useContext, useEffect, useReducer } from 'react'
import '../../CSS/Base.css'
import LogoutIcon from '@mui/icons-material/Logout'
import { Button, Grid, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'
import { LogindataReducer } from '../../Reducers/LogindataReducer'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Fade from '@mui/material/Fade'
import { GetLogin } from '../../API/LoginModel'
import { UserContext } from '../Context/UserContext'

const NavbarComp = () => {
  let logid = sessionStorage.getItem('logid')
  // logindata=JSON.parse(logindata);
  // alert(logindata);
  if (logid == '' || logid <= 0) {
    window.location = '/'
  }
  
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  let tabledata={};
  const {    
    logdata,
    setlogdata_dispatch,
  } = useContext(UserContext);
  const getData = async () => {
    let customwhr = ''
    tabledata = await GetLogin({ logid: logid })
    setlogdata_dispatch({type:"add",data:tabledata.data[0]})
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <div className="main-content">
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <h1 className="primcolor">AceChaser</h1>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          sx={{ display: 'flex', justifyContent: 'end', paddingRight: '10px' }}
        >
          <Tooltip title="Logout">           
          <Button
            id="fade-button"
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <LogoutIcon />
          </Button>
          </Tooltip>
          {/* <Link
            to="/"
            onClick={() => {
              sessionStorage.setItem('logid', 0)
            }}
          >
            <LogoutIcon />
          </Link> */}
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={handleClose}>
              {logdata[0]?.uname}-{logdata[0]?.role}<br/>
              {logdata[0]?.emailid}
             </MenuItem>
             <hr/>            
            <MenuItem onClick={()=>{sessionStorage.setItem('logid', 0);window.location.href="/";}}>Logout</MenuItem>
          </Menu>
          {/* </Tooltip> */}
        </Grid>
      </Grid>

      {/* <div className="search-wrapper">
          <span className="ti-search"></span>
          <input type="search" placeholder="Search" />
        </div> */}
    </div>
  )
}

export default NavbarComp
