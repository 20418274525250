import React from "react";
import Footer from "../../Componants/Navbar/Footer";
import NavbarComp from "../../Componants/Navbar/NavbarComp";
import Show from "../../Componants/Shows/Show";
 import SidebarComp from "../../Componants/Sidebar/SidebarComp";
  
const ShowScreen = () => {
  return (
    <div>
      <SidebarComp />
      <NavbarComp />
     <Show/>
     <Footer/>
    </div>
  );
};

export default ShowScreen;
