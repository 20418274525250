import React from "react";
import { Link } from "react-router-dom";
import "../../CSS/Base.css";
import imgticket from "../../Image/ticket.png"
import ArchiveIcon from '@mui/icons-material/Archive';
const SidebarComp = () => {
  return (
    <>
      <input type="checkbox" id="sidebar-toggle" />
      <div className="sidebar">
        <div className="sidebar-header">
          <h3 className="brand">
            {/* <span style={{ fontSize: "25px" }}>
               <img src={imgticket} height="150px" width="150px"></img>
               
               </span> */}
           
            <span style={{ padding: "10px", fontSize: "25px" }}>AceChaser</span>
          </h3>
          <label for="sidebar-toggle" className="ti-menu-alt"></label>
        </div>

        <div className="sidebar-menu">
          <ul>
          <li>
              <Link to="/home" state={{ from: [] }} style={{display:"none"}}>
                <span className="ti-dashboard"></span>
                <span>Dashboard</span>
              </Link>
             
            </li>
           
            <li>
              <Link to="/vendorlist" state={{ from: [] }}>
                <span className="ti-user"></span>
                <span>Draw Committee</span>
              </Link>
            </li>
            <li style={{display:"none"}}>
              <Link to="/venueslist" state={{ from: [] }}>
                <span className="ti-location-pin"></span>
                <span>Draw Address</span>
              </Link>
            </li>
            <li style={{display:"none"}}>
              <a href="/eventlist">
                <span className="ti-calendar"></span>
                <span>Event</span>
              </a>
            </li>
            <li>
              <a href="/showlist">
                <span className="ti-layout-media-right-alt"></span>
                <span>Draw</span>
              </a>
            </li>
            <li>
              <a href="/archivelist" style={{display:"flex",alignItems:"center"}}>
                <span className="ti-archive"></span>
                <span>Archive Draw</span>
              </a>
            </li>
            <li>
              <a href="/report">
                <span className="ti-agenda"></span>
                <span>Order Report</span>
              </a>
            </li>
            <li>
              <Link to="/adminuserlist" state={{ from: [] }}>
                <span className="ti-user"></span>
                <span>Draw User List</span>
              </Link>
            </li>
            <li style={{display:"none"}}>

              <a href="/userreport">
                <span className="ti-agenda"></span>
                <span>User Report</span>
              </a>
            </li>
           
            <li>
              <Link to="/enquirylist" state={{ from: [] }}>
                <span className="ti-comment-alt"></span>
                <span>Emails Received</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SidebarComp;
