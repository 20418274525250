import { createStore } from "state-pool";

export const venustore = createStore();
venustore.setState("venuedit", {});

export const VenuStore = (venuedit, data) => {
  //   alert(JSON.stringify(data));
  switch (data.type) {
    case "venuedit":
      venuedit(data.data);
      //   eventedit.eventid = "1";
      return 1;
    case "clearvenuedit":
      venuedit(data.data);
      //   eventedit.eventid = "1";
      break;
    default:
      break;
  }
};
