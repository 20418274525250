import React from 'react';
import { createStore } from 'state-pool';


export const Passwordstore = createStore();  // Create store for storing our global state
Passwordstore.setState("pswinputdisplya", {pswtext: "flex", sendotp: "flex", otp: "none", verifyotp: "none", password:"none" ,cpassword:"none",submit:"none"});




 export const PswStore=(pswinputdisplya,data)=>{
    // alert(JSON.stringify(data))
     switch (data.type) {
        case "sendotp":    
        // alert() 
        pswinputdisplya.sendotp="none";
        pswinputdisplya.otp="flex";
        pswinputdisplya.verifyotp="flex";
      // pswinputdisplya("pswinputdisplya",{verifyotp:"flex"})
        break;
        case "verifyotp":    
        // alert() 
        pswinputdisplya.otp="none";
        pswinputdisplya.verifyotp="none";
        pswinputdisplya.password="flex";
        pswinputdisplya.cpassword="flex";
        pswinputdisplya.submit="flex";
      // pswinputdisplya("pswinputdisplya",{verifyotp:"flex"})
        break;
        // case "logindata":            
        //     logindata.name=data.name;
        //     logindata.logid=data.logid;
        //     logindata.email=data.email;
        //     break;
        default:
            break;
     }
        
   
 
}





// export default LogStore;