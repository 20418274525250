import React from "react";
import Footer from "../../Componants/Navbar/Footer";
import NavbarComp from "../../Componants/Navbar/NavbarComp";
import ShowList from "../../Componants/Shows/ShowsList";
 import SidebarComp from "../../Componants/Sidebar/SidebarComp";
  
const ShowTblScreen = () => {
  return (
    <div>
     <SidebarComp />
     <NavbarComp />
     <ShowList/>
     <Footer/>
    </div>
  );
};

export default ShowTblScreen;
