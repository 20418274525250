import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function TextSuggestProvience({ setState, state }) {
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      size="small"
      value={state}
      options={State}
      onChange={(e, newValue) => setState(newValue.label)}
      renderInput={(params) => <TextField {...params} label="Province" />}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const State = [
  { label: "Alberta", id: "Alberta" },
  { label: "British Columbia", id: "British Columbia" },
  { label: "Manitoba", id: "Manitoba" },
  { label: "New Brunswick", id: "New Brunswick" },
  { label: "Newfoundland and Labrador", id: "Newfoundland and Labrador" },
  { label: "Northwest Territories", id: "Northwest Territories" },
  { label: "Nova Scotia", id: "Nova Scotia" },
  { label: "Nunavut", id: "Nunavut" },
  { label: "Ontario", id: "Ontario" },
  { label: "Prince Edward Island", id: "Prince Edward Island" },
  { label: "Quebec", id: "Quebec" },
  { label: "Saskatchewan", id: "Saskatchewan" },
  { label: "Yukon", id: "Yukon" },
];
