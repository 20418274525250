import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import React from "react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DoneIcon from "@mui/icons-material/Done";
import VendorTbl from "../../Table/VendorTbl";
import { Link } from "react-router-dom";
const VendorList = () => {
   
  return (
    <div className="main-content">
      <main>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item md={12} xs={12} sm={12}>
            <h3 className="supcolor3">Draw Committee List</h3>
            <hr />
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            {/* <Link to="/vendor"> */}
              <Button
                variant="contained"
                type="button"
                style={{ float: "right" }}
                onClick={()=>{window.location.href="/vendor";}}
              >
                Add New Committee
              </Button>
            {/* </Link> */}
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <VendorTbl />
          </Grid>
        </Grid>
      </main>
    </div>
  );
};

export default VendorList;
