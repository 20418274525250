import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { GetShow } from "../API/ShowModel";

export default function ShownameSuggest({ showname, setShowname }) {
  const [rows, setRows] = useState([]);
  let tabledata = {};
  const formattedDateTime = (dateTime) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const date = new Date(dateTime).toLocaleDateString('en-GB', options);
    
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    const time = new Date(dateTime).toLocaleTimeString('en-US', timeOptions);
    
    return `${date} ${time}`;
  };
  const getData = async () => {
    tabledata = await GetShow();
    // alert(JSON.stringify(tabledata.data));
    const arr = [];

    // if (tabledata.length > 0)
    tabledata.data.forEach((element) => {
      arr.push({
        label: element?.showdetail[0]?.showname + "("+formattedDateTime(element?.show_time)+")",
        drawnm:element?.showdetail[0]?.showname,
        id: element?.showdetail[0]?.showname,
      });
      setRows([...arr]);
    });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      fullWidth
      size="small"
      value={showname}
      options={rows}
      onInputChange={(event, newInputValue) => {
        setShowname("");
      }}
      onChange={(event, newValue) => {              
        setShowname(newValue?newValue?.drawnm:'');
      }}
      //   onChange={(e, newValue) => setProfilevehical(newValue.label)}
      renderInput={(params) => <TextField {...params} label="Draws" />}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
