import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Autocomplete, Button, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import ListIcon from "@mui/icons-material/List";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DeleteVendor, GetVender } from "../API/VendorModel";
import { tostmsg } from "../MIS/Global";
import AlertMessageComp from "../Componants/AlertMessageCom/AlertMessageComp";
import { useContext } from "react";
import { UserContext } from "../Componants/Context/UserContext";
import { DeleteEvent, GetEvent } from "../API/EventModel";
import { EventStore, eventstore } from "../Componants/Context/EventReducer";

const columns = [
  { label: "Vendor", minWidth: "40%" },
  { label: "Type", minWidth: "25%" },
  { label: "Event Name", minWidth: "15%" },
  { label: "", minWidth: "10%" },
];

function createData(eventid, vendrname, type, eventname, search) {
  return {
    eventid,
    vendrname,
    type,
    eventname,
    search,
  };
}

export default function EventTbl(props) {
  const [rows, setRows] = useState([{ id: "1" }]);
  const [srows, setSrows] = useState([{ id: "1" }]);
  const [searched, setSearched] = useState("");

  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);

  const { navigate, eventedit, setEventedit } = useContext(UserContext);

  let tabledata = {};

  const getEditdata = async (id) => {
    tabledata = await GetEvent({ eventid: id });
    let x = EventStore(setEventedit, {
      type: "eventedit",
      data: tabledata.data,
    });
    return x;
  };

  const getData = async () => {
    setRows([]);
    setSrows([]);
    // let data = { fdate: monday, tdate: saturday };
    tabledata = await GetEvent();

    // alert(JSON.stringify(tabledata.data));
    const arr = [];
    tabledata.data.forEach((element) => {
      let search = element.eventname+"-"+element.vendrname;
      arr.push(
        createData(
          element.eventid,
          element.vendrname,
          element.type,
          element.eventname,
          search
        )
      );
      setRows([...arr]);
      setSrows([...arr]);
    });
  };

  useEffect(() => {
    getData();
  }, [props.searchdata]);

  const requestSearch = (searchedVal, srows) => {
    const filteredRows = srows.filter((row) => {
      return row.search.toLowerCase().includes(searchedVal.toLowerCase());
    });
    return filteredRows;
  };

  const search = (searchedVal) => {
    let filteredRows = requestSearch(searchedVal, srows);
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className="">
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <Paper sx={{ width: "100%" }}>
        <TextField
          placeholder="search"
          fullWidth
          className="searchbar"
          onChange={(searchVal) => search(searchVal.target.value)}
        />

        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ fontSize: "100pt!important" }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ width: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow style={{ cursor: "pointer" }} hover tabIndex={-1}>
                      <TableCell>{row.vendrname}</TableCell>
                      <TableCell>
                        <div>{row.type}</div>
                      </TableCell>
                      <TableCell>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {row.eventname}
                        </div>
                      </TableCell>

                      <TableCell style={{ color: "#7978e9" }}>
                        <EditIcon
                          onClick={() => {
                            (async () => {
                              try {
                                let ret = getEditdata(row.eventid);
                                setTimeout(() => {
                                  navigate("/event");
                                }, 1000);
                              } catch (e) {}
                            })();
                          }}
                        ></EditIcon>
                        <DeleteForeverIcon
                          style={{ color: "red" }}
                          onClick={() => {
                            (async () => {
                              try {
                                // alert(row.bnrid);
                                if (
                                  window.confirm(
                                    "Are You Sure....delete this record"
                                  ) == false
                                ) {
                                  return;
                                }
                                const bannerdelete = await DeleteEvent({
                                  eventid: row.eventid,
                                });
                                let rettostmsg = tostmsg(bannerdelete);
                                setAlertdata(rettostmsg);
                                setAlertopen(rettostmsg.open);
                                if (bannerdelete.code == 100) {
                                  getData();
                                }
                                if (bannerdelete.code == 200) {
                                  // alert(brand.msg);
                                  setTimeout(() => {
                                    getData();
                                  });
                                } else {
                                  // alert(categorydelete.msg);
                                }
                              } catch (e) {}
                            })();
                          }}
                        />
                      </TableCell>
                      {/* AddIcon  
                        ListIcon  
                        PictureAsPdfIcon  
                        SavedSearchIcon   */}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[100, 250, 500, 1000]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
