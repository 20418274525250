import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DoneIcon from "@mui/icons-material/Done";
import VendornameSuggest from "../../Textsuggestfield/VendornameSuggest";
import { useNavigate } from "react-router-dom";
import AlertMessageComp from "../AlertMessageCom/AlertMessageComp";
import { alphanumeric, tostmsg } from "../../MIS/Global";
import { SaveEvent } from "../../API/EventModel";
import { UserContext } from "../Context/UserContext";
import { EventStore } from "../Context/EventReducer";
const Event = () => {
  const { eventedit, setEventedit } = useContext(UserContext);
  const [type, setType] = useState(eventedit[0]?.type || "Recurring");
  const [eventname, setEventname] = useState(eventedit[0]?.eventname || "");
  const [eventid, setEventid] = useState(eventedit[0]?.eventid);
  const [descrp, setDescrp] = useState(eventedit[0]?.descrp || "");
  const [uid, setUid] = useState(eventedit[0]?.uid || 0);
  const [vendrid, setVendrid] = useState(eventedit[0]?.vendrid || 0);
  const [vendrname, setVendrname] = useState(eventedit[0]?.vendrname || "");
  const [alertdata, setAlertdata] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);

  const navigate = useNavigate();

  let data = {
    eventid: eventid,
    vendrid: vendrid,
    type: type,
    eventname: eventname,
    descrp: descrp,
    uid: uid,
  };
  const Owner = [
    { label: "Recurring", id: "Recurring" },
    { label: "Single Event", id: "Single Event" },
    { label: "Staggered", id: "Staggered" },
  ];

  return (
    <div className="main-content">
      <AlertMessageComp
        type={alertdata.type}
        msg={alertdata.msg}
        vertical={alertdata.vertical}
        horizontal={alertdata.horizontal}
        setOpen={setAlertopen}
        open={alertopen}
      />
      <main>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item md={12} xs={12} sm={12}>
            <h3 className="supcolor3">Event Create</h3>
            <hr />
          </Grid>
          <Grid item md={4} xs={12} sm={12}>
            <VendornameSuggest
              setVendrid={setVendrid}
              vendrname={vendrname}
              setVendrname={setVendrname}
            />
          </Grid>
          <Grid item md={4} xs={12} sm={12}>
            <Autocomplete
              autoFocus
              disablePortal
              id="combo-box-demo"
              size="small"
              // defaultValue="Recurring"
              value={type}
              options={Owner}
              onChange={(e, newValue) => setType(newValue.label)}
              renderInput={(params) => <TextField {...params} label="Type" />}
            />
          </Grid>
          <Grid item md={4} xs={12} sm={12}>
            <TextField
              size="small"
              id="outlined-case no-input"
              fullWidth
              value={eventname}
              label="Event Name"
              inputProps={{ maxLength: 20 }}
              onChange={async (e) => {
                let retdt = await alphanumeric(e);
                setEventname(retdt);
              }}
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <TextareaAutosize
              minRows={3}
              placeholder="Description"
              style={{ width: "100%" }}
              value={descrp}
              onChange={async (e) => {
                let retdt = await alphanumeric(e);
                setDescrp(retdt);
              }}
            />
          </Grid>

          <Grid item md={12} xs={12} sm={12}>
            <Button
              variant="contained"
              type="button"
              style={{ float: "right" }}
              onClick={() => {
                (async () => {
                  try {
                    const event = await SaveEvent(data);
                    let rettostmsg = tostmsg(event);
                    // alert(JSON.stringify(event));
                    setAlertdata(rettostmsg);
                    setAlertopen(rettostmsg.open);

                    if (event.code == 100) {
                    }

                    if (event.code == 200) {
                      setTimeout(() => {
                        EventStore(setEventedit, {
                          type: "cleareventedit",
                          data: {},
                        });
                        navigate("/eventlist");
                      });
                    }
                  } catch (e) {}
                })();
              }}
            >
              <DoneIcon /> Submit
            </Button>
            <Button
              variant="contained"
              type="button"
              className="primcolor1_bg"
              style={{
                float: "right",
                backgroundColor: "#f3797e",
                marginRight: "10px",
              }}
              onClick={()=>{window.location.reload()}}
            >
              <RestartAltIcon /> Reset
            </Button>
          </Grid>
        </Grid>
      </main>
    </div>
  );
};

export default Event;
